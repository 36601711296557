import { HttpEvent } from '@angular/common/http';
import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Control } from 'src/app/form-v2/form-model/Control';
import { FormV2ServiceService } from 'src/app/form-v2/form-v2-service.service';
import { OrderRegisterService } from 'src/app/module-order-register/order-register.service';
import { DynamicFormComponent } from 'src/app/new-form/initial-form/dynamic-form/dynamic-form.component';
import { StatusEnum } from 'src/app/shared/enums/status-enum';
import { TranslationEnum } from 'src/app/shared/enums/translation-enum';
import { Parameter } from 'src/app/shared/models/form-model/models/form-controlls.model';
import { OrderListData } from 'src/app/shared/models/order-register/order-list/order-list-data.model';
import { OrderList } from 'src/app/shared/models/order-register/order-list/order-list.model';
import { OrderRegisterStatuesQuantity } from 'src/app/shared/models/order-register/order-register-statuses-quantity.model';
import { Contractor } from 'src/app/shared/models/user/contractor.model';

@Component({
  selector: 'app-parent-control',
  templateUrl: './parent-control.component.html',
  styleUrls: ['./parent-control.component.scss']
})
export class ParentControlComponent implements OnInit, AfterViewInit {
  public TranslationEnum = TranslationEnum;
  Data: OrderListData;
  AllData: OrderListData;
  contractorNumber: any;
  zakladka: string = '';
  selected_id:number = 1;
  pageIndex: number = 0;
  pageSize: number = 100000;
  tresc: string = '';
  control: Control;
  Prefix: string = '';
  loading:boolean = true;

  lista:string[] = ['opracowywane', 'zamowiono', 'realizacje', 'wstrzymano', 'zakonczono', 'anulowano'] ;

  displayedColumns = ['B2BId', 'ValueNetto', 'ValueBrutto', 'Currency', 'Quantity', 'OrderDateCreate'];
  dataSource: any;

  constructor(public dialogRef: MatDialogRef<DynamicFormComponent>,private orderRegisterService: OrderRegisterService,  @Inject(MAT_DIALOG_DATA) public _control: Control, private formService: FormV2ServiceService) {
    this.control = _control;
    this.dataSource = new MatTableDataSource<any>();
   //  console.log("sss", _control);
    this.Load();
    

  }
  Load(){
    
    this.control.Values.forEach(v => {
      // console.log("cc", v.Key);
      this.Prefix = v.Key;
    });
    const contractor = JSON.parse(sessionStorage.getItem('_slc')) as Contractor;
    this.contractorNumber = contractor.ContractorNumber;
   //  console.log("test");
    this.orderRegisterService.getOrders(
      this.contractorNumber,
      this.selected_id,
      'Prefix',
      this.Prefix,
      'B2BId',
      'desc',
      this.pageIndex,
      this.pageSize,
      '2000-01-01',
      '2200-01-01',
      false,
      false
    ).subscribe(data => {
      this.AllData = data;
      this.Filtruj();
    }, err => {
      this.loading = false;
    });
  }
  applyFilter(tresc){
    this.tresc = tresc;
    this.Filtruj();
    
  }
  Filtruj(){
    this.Data = new OrderListData();
    this.Data.Orders = [];
    
    this.AllData.Orders.forEach(v => {
      if(v.Prefix == this.Prefix && (v.B2BId != null && v.B2BId.toLowerCase().includes(this.tresc.toLowerCase())) || (v.ProductionNumber!= null && v.ProductionNumber.toLowerCase().includes(this.tresc.toLowerCase()))){
        this.Data.Orders.push(v);
      }
    });
    this.dataSource.data = this.Data.Orders;
    this.loading = false;
    // console.log("data", this.Data);
  }
  public LoadParams(orderId){
    this.formService.getOrderParameters(orderId).
    subscribe((data) => {
      this.control.ParentParameters = data.Parameters;
    
      this.loading = false;
      this.dialogRef.close();
    });
  }
  ngAfterViewInit(): void {


  }

  ngOnInit(): void {
    

  }
  wybierz(v:string){
    
    this.loading = true;
    console.log("wybrano", v);
    var p = new Parameter();
    p.Disabled = false;
    p.DisplayValue = v;
    p.DisplayValueCs = v;
    p.DisplayValueDe = v;
    p.DisplayValueEn = v;
    p.DisplayValueFr = v;
    p.DisplayValueIt = v;
    p.DisplayValuePl = v;
    p.Key = this.control.KeyName;
    
    // p.Module = v.Module.valueOf();
    // p.Position = v.Position.valueOf();
    p.Type = this.control.ControlType;
    p.Value = v;
    p.IsPrint = false;
    
    this.control.wybrany = p;
    this.LoadParams(v);
  }
  sprawdzListe(zakladka){
    switch (zakladka) {
      case 'opracowywane':
        return 1;
        break;
      case 'zamowiono':
        return 2;
        break;
      case 'realizacje':
        return 3;
        break;
      case 'wstrzymano':
        return 4;
        break;
      case 'zakonczono':
        return 5;
        break;
      case 'anulowano':
        return 6;
        break;
    }
    return 1;
  }
  wybierzListe(zakladka){
    this.selected_id = this.sprawdzListe(zakladka);
    this.loading = true;
    this.Load();

  }

}

import { TreeTechnologyListComponent } from './module-tree-technology/tree-technology-list/tree-technology-list.component';
import { TreeTechnologyComponent } from './module-tree-technology/tree-technology/tree-technology.component';
import { StylePPipe } from './shared/pipes/style-p.pipe';
import { VersionCheckService } from './shared/service/version-check.service';
import { AuthGuard } from './shared/guard/auth-guards';
import { TranslationDirective } from './shared/directives/translation.directive';
import { DataSharingService } from './shared/service/data-sharing.service';
import { MaterialModule } from './material/material.module';
import { UserService } from './user.service';
import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { APP_INITIALIZER, Injector } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbAlertModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxPaginationModule } from 'ngx-pagination';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { KonfiguratorComponent } from './konfigurator/konfigurator.component';
import { TresciService } from './tresci.service';
import { ArchiwumComponent } from '../stare/archiwum/archiwum.component';
import { HistoriaComponent } from '../stare/historia/historia.component';
import { CookieService } from 'ngx-cookie-service';
import { ExceptionsMenagerService } from './exceptions-menager.service';
import { HttpClientJsonpModule, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { SafeHtmlPipe } from './safe-html';
import { KonfiguratorformComponent } from './konfiguratorform/konfiguratorform.component';
import { PodziekowanieComponent } from '../stare/podziekowanie/podziekowanie.component';
import { HomeComponent } from './home/home.component';
import { SampleSectionComponent } from './components/sample-section.component';
import { ContractorChooseDialogComponent } from './module-menu/contractor-choose-dialog/contractor-choose-dialog.component';
import { PanelComponent } from './panel/panel.component';
import { SearchContractorPipe } from './search-contractor.pipe';
import { SzczegolyComponent } from './szczegoly/szczegoly.component';
import { MatchValueDirective } from './panel/directives/match-value.directive';
import { FakturyComponent } from './faktury/faktury.component';
import { ZaplataComponent } from './zaplata/zaplata.component';
import { PriceFormatPipe } from './PriceFormat';
import { NeedchangepasswordComponent } from './needchangepassword/needchangepassword.component';
import { Config } from './config';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { OrderWarningsDialogComponent } from './module-order-register/order-list/order-warning-dialog/order-warnings-dialog.component';
import { UniversalDialogComponent } from './shared/components/universal-dialog/universal-dialog.component';
import { OrderResponseDialogComponent } from './module-order-register/order-list/order-warning-dialog/order-response-dialog/order-response-dialog.component';
import { ProformsComponent } from './module-order-register/proforms-list/proforms-list.component';
import { ComponentsModule } from './shared/components';
import { DynamicFormModule } from './new-form/initial-form/dynamic-form/dynamic-form.module';
import { InitialFormModule } from './new-form/initial-form/initial-form.module';
import { OrderNoteDialogComponent } from './module-order-register/order-list/order-note-dialog/order-note-dialog.component';
import { PaymentConfirmDialogComponent } from './module-order-register/proforms-list/payment-confirm-dialog/payment-confirm-dialog.component';
import { MatNativeDateModule } from '@angular/material/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { InterceptorService } from './shared/async-services/http/http.interceptor';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { CustomTranslateLoader } from './shared/language-loader/CustomTranslateLoader';
import { OrderRegisterComponent } from './module-order-register/order-register/order-register.component';
import { OrderListComponent } from './module-order-register/order-list/order-list.component';
import { OrderPackageDialogComponent } from './module-order-register/order-list/order-package-dialog/order-package-dialog.component';
import { OrderEstimateDialogComponent } from './module-order-register/order-list/order-estimate-dialog/order-estimate-dialog.component';
import { SendMailWithOrderDialogComponent } from './module-order-register/order-list/send-mail-dialog/send-mail-with-order-dialog.component';
import { DialogPositionComponent } from './new-form/initial-form/dialog-position/dialog-position.component';
import { DirectivesModule } from './shared/directives/directives.module';
import { FabricDialogComponent } from './shared/components/form-controls/fabric/fabric-dialog/fabric-dialog.component';
import { ModuleComponent } from './shared/components/form-controls/module/module.component';
import { FooterComponent } from './shared/footer/footer.component';
import { SystemsComponent } from './module-systems/systems/systems.component';
import { IsLoggedGuard } from './shared/guard/is-logged-guard';
import { NotificationComponent } from './notifications/notification/notification.component';
import { NotificationsHistoryComponent } from './notifications/notifications-history/notifications-history.component';
import { NotificationsModalComponent } from './notifications/notifications-modal/notifications-modal.component';
import { MenuComponent } from './module-menu/menu/menu.component';
import { LoginComponent } from './module-login/login/login.component';
import { ResetPasswordComponent } from './module-login/reset-password/reset-password.component';
import { ContactDialogComponent } from './module-login/contact-dialog/contact-dialog.component';
import { UniversalSnackBarComponent } from './shared/components/universal-snack-bar/universal-snack-bar.component';
import { ModuleMaintenanceComponent } from './module-maintenance/module-maintenance.component';
import { SelectMultiColumnDialogComponent } from './shared/components/form-controls/select-multi-column/select-multi-column-dialog/select-multi-column-dialog.component';
import { OrderAttachmentDialogComponent } from './module-order-register/order-list/order-attachment-dialog/order-attachment-dialog.component';
import { VisualizationComponent } from './shared/components/form-controls/visualization/visualization.component';
import { PreviewFileDialogComponent } from './shared/components/preview-file/preview-file-dialog.component';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { OrderEditDialogComponent } from './module-order-register/order-list/order-edit-dialog/order-edit-dialog.component';
import { InvoicesComponent } from './module-invoices/invoices.component';
import { DeliveryListComponent } from './module-order-register/delivery-list/delivery-list.component';
import { DeliveryListDialogComponent } from './module-order-register/delivery-list/delivery-list-dialog/delivery-list-dialog.component';
import { VisualizationDialogComponent } from './shared/components/form-controls/visualization/visualization-dialog/visualization-dialog.component';
import { DeliveryListNewDialogComponent } from './module-order-register/delivery-list/delivery-list-new-dialog/delivery-list-new-dialog.component';
import { ReklamacjeComponent } from './reklamacje/reklamacje.component';
import { ReklamacjePozComponent } from './reklamacje/reklamacje-poz/reklamacje-poz.component';
import { DatePipe, LOCATION_INITIALIZED } from '@angular/common';
import { FormV2Component } from './form-v2/form-v2.component';
import { FormStepComponent } from './form-v2/form-step/form-step.component';
import { CheckSelectMultiColumnComponent } from './shared/components/form-controls/select-multi-column-check/check-select-multi-column.component';
import { BrakiComponent } from './braki/braki.component';
import { FormDeliveryComponent } from './form-v2/form-delivery/form-delivery.component';
import { ComplaintOrderListComponent } from './module-order-register/complaint-order-list/complaint-order-list.component';
import { FormControlComponent } from './form-v2/form-control/form-control.component';
import { KimControlComponent } from './form-v2/form-control/controls/kim-control/kim-control.component';
import { FabricControlComponent } from './form-v2/form-control/controls/fabric-control/fabric-control.component';
import { AddAdressComponent } from './form-v2/form-delivery/add-adress/add-adress.component';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { ViewJSONComponent } from './form-v2/form-control/controls/view-json/view-json.component';
import { FormV2PreviewComponent } from './form-v2-preview/form-v2-preview.component';
import { DokumentyComponent } from './dokumenty/dokumenty.component';
import { StrefaKlentaComponent } from './strefa-klenta/strefa-klenta.component';
import { SelectMultiColumnControlComponent } from './form-v2/form-control/controls/select-multi-column-control/select-multi-column-control.component';
import { EdytujSlupComponent } from './form-v2/form-control/controls/edytuj-slup/edytuj-slup.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ParentControlComponent } from './form-v2/form-control/controls/parent-control/parent-control.component';
import { ParentmodControlComponent } from './form-v2/form-control/controls/parent-control/parentmod-control/parentmod-control.component';
import { ParentpozControlComponent } from './form-v2/form-control/controls/parent-control/parentpoz-control/parentpoz-control.component';
import { ChildListComponent } from './module-order-register/child-list/child-list.component';

export function appInitializerFactory(translate: TranslateService, injector: Injector, cookieService: CookieService) {
  
  return () =>
    new Promise<any>((resolve: any) => {
      const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
      locationInitialized.then(() => {
        const langToSet = cookieService.get('lang');
        if (cookieService.check('lang')) {
          window['culture'] = cookieService.get('lang');
        } else {
          let useLang = translate.getBrowserLang();
          const langs = translate.getLangs();
          if (!langs.includes(useLang)) {
            useLang = 'EN';
          }
          window['culture'] = useLang;
        }
        let lang = langToSet;
        if(langToSet == undefined || langToSet == ""){
          lang = 'pl';
        }
        translate.use(lang).subscribe( 
          () => {
            console.info(`Successfully initialized '${langToSet}' language.'`);
          },
          (err) => {
            console.error(`Problem with '${langToSet}' language initialization.'`);
          },
          () => {
            resolve(null);
          }
        );
      });
    });
}


@NgModule({
  declarations: [
    AppComponent,
    KonfiguratorComponent,
    FooterComponent,
    ArchiwumComponent,
    HistoriaComponent,
    SafeHtmlPipe,
    KonfiguratorformComponent,
    PodziekowanieComponent,
    HomeComponent,
    SampleSectionComponent,
    // TlumaczComponent,
    ContractorChooseDialogComponent,
    PanelComponent,
    SearchContractorPipe,
    SzczegolyComponent,
    MatchValueDirective,
    FakturyComponent,
    ZaplataComponent,
    PriceFormatPipe,
    NeedchangepasswordComponent,
    OrderWarningsDialogComponent,
    UniversalDialogComponent,
    OrderResponseDialogComponent,
    ProformsComponent,
    OrderNoteDialogComponent,
    PaymentConfirmDialogComponent,
    TreeTechnologyComponent,
    TreeTechnologyListComponent,
    OrderRegisterComponent,
    OrderListComponent,
    DeliveryListComponent,
    OrderPackageDialogComponent,
    OrderEstimateDialogComponent,
    SendMailWithOrderDialogComponent,
    DialogPositionComponent,
    ModuleComponent,
    SystemsComponent,
    NotificationsModalComponent,
    NotificationComponent,
    NotificationsHistoryComponent,
    MenuComponent,
    LoginComponent,
    ResetPasswordComponent,
    ContactDialogComponent,
    UniversalSnackBarComponent,
    ModuleMaintenanceComponent,
    StylePPipe,
    SelectMultiColumnDialogComponent,
    OrderAttachmentDialogComponent,
    PreviewFileDialogComponent,
    VisualizationComponent,
    OrderEditDialogComponent,
    InvoicesComponent,
    DeliveryListDialogComponent,
    VisualizationDialogComponent,
    DeliveryListNewDialogComponent,
    ReklamacjeComponent,
    ReklamacjePozComponent,
    FormV2Component,
    FormStepComponent,
    BrakiComponent,
    FormDeliveryComponent,
    ComplaintOrderListComponent,
    FormControlComponent,
    KimControlComponent,
    FabricControlComponent,
    AddAdressComponent,
    ViewJSONComponent,
    FormV2PreviewComponent,
    DokumentyComponent,
    StrefaKlentaComponent,
    SelectMultiColumnControlComponent,
    EdytujSlupComponent,
    ParentControlComponent,
    ParentmodControlComponent,
    ParentpozControlComponent,
    ChildListComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    HttpClientJsonpModule,
    FormsModule,
    NgbModule,
    NgxJsonViewerModule,
    // TreeModule.forRoot(),
    ReactiveFormsModule,
    NgxPaginationModule,
    BrowserAnimationsModule,
    MaterialModule,
    MatNativeDateModule,
    InitialFormModule,
    DynamicFormModule,
    ComponentsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: CustomTranslateLoader,
      },
    }),
    DirectivesModule,
    NgbAlertModule,
    DragDropModule
  ],
  providers: [
    AuthGuard,
    IsLoggedGuard,
    TresciService,
    UserService,
    CookieService,
    ExceptionsMenagerService,
    Config,
    DataSharingService,
    DatePipe,
    VersionCheckService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true,
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'outline' },
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService, Injector, CookieService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
  exports: [PriceFormatPipe, StylePPipe, TranslationDirective],
  entryComponents: [
    OrderWarningsDialogComponent,
    UniversalDialogComponent,
    OrderResponseDialogComponent,
    OrderNoteDialogComponent,
    PaymentConfirmDialogComponent,
    SendMailWithOrderDialogComponent,
    ContractorChooseDialogComponent,
    DialogPositionComponent,
    FabricDialogComponent,
    NotificationsModalComponent,
    ContactDialogComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}


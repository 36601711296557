import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Control } from 'src/app/form-v2/form-model/Control';
import { FormDataV2 } from 'src/app/form-v2/form-model/FormDataV2';
import { DynamicFormComponent } from 'src/app/new-form/initial-form/dynamic-form/dynamic-form.component';
import { TranslationEnum } from 'src/app/shared/enums/translation-enum';
import { Parameter } from 'src/app/shared/models/form-model/models/form-controlls.model';

@Component({
  selector: 'app-parentpoz-control',
  templateUrl: './parentpoz-control.component.html',
  styleUrls: ['./parentpoz-control.component.scss']
})
export class ParentpozControlComponent implements OnInit {
  public TranslationEnum = TranslationEnum;
  public control:Control;
  public FormData: FormDataV2;
  public list:number;
  parametry: any[] = [];
  wyswietlane: any[] = [];

  constructor(public dialogRef: MatDialogRef<DynamicFormComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.control = data.control;
    this.FormData = data.formData;
    this.list = data.list;
  }

  ngOnInit(): void {
    console.log("pp", this.FormData.parent_Parameters);
    console.log("aktmod", this.FormData.supervisor.getAktualnyMod());
    this.FormData.parent_Parameters.forEach(element => {
     
      if(element.ControlName == 'Number' || element.ControlName == 'Select' || element.ControlName == 'Check' || element.ControlName == 'Text' ){
        if(element.DisplayValue != "" && element.DisplayValue != null){
          if(element.Module == this.FormData.supervisor.getAktualnyMod()){
            while(element.Position >= this.parametry.length){
              this.parametry.push([]);
              this.wyswietlane.push([]);
            }
            
            this.parametry[element.Position].push(element);
            if(element.Key == 'PozDotyczy' || element.Key.toLowerCase() == 'wysokość' || element.Key.toLowerCase() == 'szerokość' || element.Key.toLowerCase() == 'ośszerokość' || element.Key.toLowerCase() == 'ośwysokość' || element.Key.toLowerCase() == 'moduł'){
              this.wyswietlane[element.Position].push(element);
            }
          
          }
        }
       
      }
      
    });
    console.log("--", this.parametry);
  }

  ustawPoz(v){
    console.log("wybrano", v);
    var p = new Parameter();
    p.Disabled = false;
    p.DisplayValue = v;
    p.DisplayValueCs = v;
    p.DisplayValueDe = v;
    p.DisplayValueEn = v;
    p.DisplayValueFr = v;
    p.DisplayValueIt = v;
    p.DisplayValuePl = v;
    p.Key = this.control.KeyName;
    // p.Module = v.Module.valueOf();
    // p.Position = v.Position.valueOf();
    p.Type = this.control.ControlType;
    p.Value = v;
    p.IsPrint = false;
    
    this.control.wybrany = p;
    this.dialogRef.close();
    
  }
}

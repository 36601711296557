import { OrderRegisterService } from '../../order-register.service';
import { MailWithOrder } from '../../../shared/models/order-register/order-list/mail-with-order.model';
import { Component, Inject, OnInit, } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TresciService } from 'src/app/tresci.service';
import { ErrorStateMatcher } from '@angular/material/core';
import { TranslationEnum } from 'src/app/shared/enums/translation-enum';



@Component({
  selector: 'app-send-mail-with-order-dialog',
  templateUrl: './send-mail-with-order-dialog.component.html',
  styleUrls: ['./send-mail-with-order-dialog.component.scss']
})

export class SendMailWithOrderDialogComponent implements OnInit {

  public TranslationEnum = TranslationEnum;

  mailGroup: FormGroup;
  matcher = new CustomErrorStateMatcher();
  public b2bid_con = '';

  constructor(public dialogRef: MatDialogRef<SendMailWithOrderDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: MailWithOrder, public tresci: TresciService, private fb: FormBuilder,
    public orderRegisterService: OrderRegisterService) {
      this.b2bid_con = data.Subject;
      data.Subject = '';

   }

   ngOnInit(): void {
    this.mailGroup = this.fb.group({
      to: ['', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$')]],
      cc: ['', Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$')],
      subject: [''],
      content: [''],
      specification: [''],
      valuation: ['']
    });

    this.mailGroup.patchValue({
      to: this.data.To,
      cc: this.data.CC,
      subject: this.data.Subject,
      content: this.data.Content,
      specification: this.data.Specification,
      valuation: this.data.Valuation
    });
  }



  onSubmit({value, valid}: {value: MailWithOrder, valid: boolean}){
    if (valid) {
      value.CultureId = '1';//window['culture'];
      value.Id = this.data.Id;
      value.Subject = this.b2bid_con + ' ' + value.Subject;
      this.orderRegisterService.sendMailWithOrder(value);
      this.dialogRef.close(true);
    }
  }

}

/** Error when invalid control is dirty, touched, or submitted. */
export class CustomErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && isSubmitted);
  }
}

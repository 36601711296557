<div class="m-2">

  <!-- Filtr column + term -->
  <div class="d-inline-block mt-3 mr-3">
    <mat-form-field appearance="outline" class="w-160p">
      <mat-select [(value)]="FilterColumn">
        <mat-option *ngFor="let item of FilterColumns" [value]="item.Name">
          {{item.Translate|translate}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="d-inline-block mt-3 mr-3">
    <mat-form-field appearance="outline" class="w-190p" color="accent">
      <input matInput (keyup.enter)="applyFilter(input)" #input placeholder="B2B/2020/03962">
      <button mat-button *ngIf="input.value" matSuffix mat-icon-button class="d-inline-block"
        (click)="applyFilter(input)">
        <mat-icon>search</mat-icon>
      </button>
      <button mat-button *ngIf="input.value" matSuffix mat-icon-button class="d-inline-block"
        (click)="input.value=''; applyFilter(input)">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
  </div>

  <!-- Filtr by date from, to -->
  <mat-form-field appearance="outline" *ngIf="status === StatusEnum.Zakonczono" class="mt-3" color="accent">
    <mat-date-range-input [formGroup]="Range" [rangePicker]="picker">
      <input matStartDate formControlName="start">
      <input matEndDate formControlName="end" (dateChange)="dateRangeChange()">
    </mat-date-range-input>
    <mat-datepicker-toggle matSuffix [for]="picker" class="d-inline-block"></mat-datepicker-toggle>
    <mat-date-range-picker #picker></mat-date-range-picker>
  </mat-form-field>

  <!-- Collective buttons  -->
  <div class="float-right mt-3 d-inline-block" *ngIf="status === StatusEnum.Opracowywane">
    <button mat-flat-button id="zamów_wiele"  class="mr-3" [translation]="TranslationEnum.Translation" color="primary"
      (click)="checkOrderAvailability(ModalForEnum.OrderMany);">
      {{'zamów_wiele'|translate}}
      <!-- <app-tlumaczt [id]="'zamów_wiele'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
    </button>

    <button mat-flat-button id="wygeneruj_proformę_zbiorczą" class="mr-3" [translation]="TranslationEnum.Translation"
      color="primary" (click)="checkOrderAvailability(ModalForEnum.PayMany);">
      {{'wygeneruj_proformę_zbiorczą'|translate}}
      <!-- <app-tlumaczt [id]="'Wygeneruj proformę zbiorczą'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
    </button>

    <button mat-flat-button id="anuluj_wiele" class="mr-3" [translation]="TranslationEnum.Translation" color="primary"
      (click)="cancelOrders();">
      {{'anuluj_wiele'|translate}}
      <!-- <app-tlumaczt [id]="'Anuluj_wiele'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
    </button>

    <button mat-flat-button id="usun_wiele" class="mr-3" [translation]="TranslationEnum.Translation" color="primary"
      (click)="removeOrders();">
      {{'usun_wiele'|translate}}
      <!-- <app-tlumaczt [id]="'Usun_wiele'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
    </button>
  </div>

  <!-- OrderList -->
  <div class="mw-100">

    <!-- Progress spinner -->
    <div class="loading-shade" *ngIf="IsLoadingResults || IsRateLimitReached">
      <mat-spinner *ngIf="IsLoadingResults"></mat-spinner>
      <div *ngIf="IsRateLimitReached" id="wystąpił_błąd_połączenia_proszę_odświeżyć_za_minutę"
        class="rate-limit-reached" [translation]="TranslationEnum.Translation">
        {{'wystąpił_błąd_połączenia_proszę_odświeżyć_za_minutę'|translate}}
        <!-- <app-tlumaczt [id]="'Wystąpił błąd połączenia. Proszę odświeżyć za minutę.'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
      </div>
    </div>

    <!-- Table -->
    <table mat-table [dataSource]="Data" matSort matSortActive="B2BId" matSortDisableClear matSortDirection="desc">

      <!-- Checkbox Column -->
      <ng-container matColumnDef="select" class=" w-75p">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox color="primary" (change)="$event ? masterToggle() : null"
            [checked]="Selection.hasValue() && isAllSelected()"
            [indeterminate]="Selection.hasValue() && !isAllSelected()">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row"> 
          <mat-checkbox color="primary" *ngIf=" (row.ProformaFormat === undefinded && row.OrderStatusId !== 12)"
            (click)="$event.stopPropagation()" (change)="$event ? Selection.toggle(row) : null"
            [checked]="Selection.isSelected(row)">
          </mat-checkbox>
          <!-- 12 - weryfikacja zamówienia -->
        </td>
      </ng-container>

      <!-- B2BId Column -->
      <ng-container matColumnDef="B2BId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header id="b2bid" [translation]="TranslationEnum.Translation">
          {{'b2bid'|translate}}
          <!--<app-tlumaczt [id]="'B2Bid'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
        </th>
        <td mat-cell *matCellDef="let row"> {{row.B2BId}} </td>
      </ng-container>

      <!-- Produkt Column -->
      <ng-container matColumnDef="Produkt">
        <th mat-header-cell *matHeaderCellDef mat-sort-header id="produkt" [translation]="TranslationEnum.Translation">
          {{ 'produkt'|translate}}
          <!--<app-tlumaczt [id]="'Produkt'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
        </th>
        <td mat-cell *matCellDef="let row" id="produkt_{{row.Produkt}}" [translation]="TranslationEnum.Translation">
          {{'produkt_' + row.Produkt|translate}}
          <!--<app-tlumaczt [id]="'produkt_' + row.Product" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
        </td>
      </ng-container>

      <!-- System Column -->
      <ng-container matColumnDef="System">
        <th mat-header-cell *matHeaderCellDef mat-sort-header id="system" [translation]="TranslationEnum.Translation">
          {{ 'system'| translate}}
        </th>
        <td mat-cell *matCellDef="let row" id="system_{{row.System}}" [translation]="TranslationEnum.Translation">
          {{('system_' + row.System)|translate}}
        </td>
      </ng-container>

      <ng-container matColumnDef="ProformaFormat">
        <th mat-header-cell *matHeaderCellDef mat-sort-header id="proforma_format" [translation]="TranslationEnum.Translation">
          {{ 'proforma_format'| translate}}
        </th>
        <td mat-cell *matCellDef="let row">
           {{ row.ProformaFormat }}
        </td>
      </ng-container>

      <!-- Quantity Column -->
      <ng-container matColumnDef="Quantity">
        <th mat-header-cell *matHeaderCellDef mat-sort-header id="ilość" [translation]="TranslationEnum.Translation">
          {{'ilość'|translate}}
          <!--<app-tlumaczt [id]="'Ilość'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
        </th>
        <td mat-cell *matCellDef="let row">{{row.Quantity}}</td>
      </ng-container>

      <!-- Applies Column -->
      <ng-container matColumnDef="Applies">
        <th mat-header-cell *matHeaderCellDef mat-sort-header id="dotyczy" [translation]="TranslationEnum.Translation">
          {{'dotyczy'|translate}}
          <!--<app-tlumaczt [id]="'Dotyczy'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
        </th>
        <td mat-cell *matCellDef="let row"> {{row.Applies}} </td>
      </ng-container>

      <!-- Description Column -->
      <ng-container matColumnDef="Description">
        <th mat-header-cell *matHeaderCellDef mat-sort-header id="uwagi" [translation]="TranslationEnum.Translation">
          {{'uwagi'|translate}}
          <!--<app-tlumaczt [id]="'Uwagi'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-icon *ngIf="row.FileForVerification" style="transform: rotate(90deg); cursor: pointer;"
            (click)="openAttachmentDialog(row.Id)">attachment</mat-icon>
          {{row.Description}}
        </td>
      </ng-container>

      <!-- PaymentStatusName Column -->
      <ng-container matColumnDef="PaymentStatusName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header id="status_płatności"
          [translation]="TranslationEnum.Translation">
          {{'status_płatności'|translate}}
          <!--<app-tlumaczt [id]="'Status_płatności'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
        </th>
        <td mat-cell *matCellDef="let row">
          <ng-container
            *ngIf="row.PaymentStatusName !== null && row.PaymentStatusName !== undefined && row.BlockingConditionId != 2">
            <mat-icon *ngIf="row.PaymentStatusId === 1" style="color:green" class="d-block mx-auto">attach_money
            </mat-icon>
            <mat-icon *ngIf="row.PaymentStatusId !== 1" class="d-block mx-auto">money_off</mat-icon>
            <span id="{{row.PaymentStatusName}}" [translation]="TranslationEnum.Translation">
              {{row.PaymentStatusName| translate}}
            </span>
            <!--<app-tlumaczt [id]="row.PaymentStatusName" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
          </ng-container>
          <ng-container *ngIf="row.BlockingConditionId !== null && row.BlockingConditionId !== undefined">
            <mat-icon class="d-block mx-auto">money_off</mat-icon>
            <span id="{{row.BlockingConditionName}}" [translation]="TranslationEnum.Translation">
              {{row.BlockingConditionName|translate}}
            </span>
            <!--<app-tlumaczt [id]="row.BlockingConditionName" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
          </ng-container>
        </td>
      </ng-container>

      <!-- TotalCost Column -->
      <ng-container matColumnDef="ValueNetto">
        <th mat-header-cell *matHeaderCellDef mat-sort-header id="cena_razem"
          [translation]="TranslationEnum.Translation">
          {{'cena_razem'|translate}}
          <!--<app-tlumaczt [id]="'Cena_razem'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
        </th>
        <td mat-cell *matCellDef="let row" class="cena" style="color: #cd0000">
          <ng-container *ngIf="row.ValueNetto !== null && row.Currency !== null && row.ValueNetto > 0">
            {{row.ValueNetto | PriceFormat}}&nbsp;
            {{row.Currency}}
            <!--<app-tlumaczt [id]="row.Currency" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> --><br />
            {{row.ValueBrutto | PriceFormat}}&nbsp;
            {{row.Currency}}
            <!--<app-tlumaczt [id]="row.Currency" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
          </ng-container>
          <ng-container *ngIf="row.ValueNetto == 0">
            ---
          </ng-container>
        </td>
      </ng-container>

      <!-- DeliveryCost Column -->
      <ng-container matColumnDef="DeliveryCostNetto">
        <th mat-header-cell *matHeaderCellDef mat-sort-header id="cena_dostawy"
          [translation]="TranslationEnum.Translation">
          {{ 'cena_dostawy'| translate}}
          <!--<app-tlumaczt [id]="'Cena_dostawy'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
        </th>
        <td mat-cell *matCellDef="let row" class="cena" style="color: #cd0000">
          <ng-container *ngIf="row.DeliveryCostBrutto !== null && row.Currency !== null && row.DeliveryCostBrutto > 0">
            {{row.DeliveryCostNetto | PriceFormat}}&nbsp;
            {{row.Currency}}
            <!--<app-tlumaczt [id]="row.Currency" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> --><br />
            {{row.DeliveryCostBrutto | PriceFormat}}&nbsp;
            {{row.Currency}}
            <!--<app-tlumaczt [id]="row.Currency" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
          </ng-container>
          <ng-container *ngIf="row.DeliveryCostBrutto == 0">
            ---
          </ng-container>
        </td>
      </ng-container>

      <!-- OrderStatusName Column -->
      <ng-container matColumnDef="OrderStatusName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header id="status" [translation]="TranslationEnum.Translation">
          {{ 'status'| translate}}
          <!--<app-tlumaczt [id]="'Status'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
        </th>
        <td mat-cell *matCellDef="let row">
          <ng-container *ngIf="row.OrderStatusId !== null">
            <span id="{{row.OrderStatusName}}" class="d-block" [translation]="TranslationEnum.Translation">{{
              row.OrderStatusName|translate}}</span>
            <!--<app-tlumaczt  [id]="row.OrderStatusName" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
            <span *ngIf="!row.IsActive" id="nieaktywne" class="d-block" [translation]="TranslationEnum.Translation">
              {{'nieaktywne'|translate}}</span>
            <!--<app-tlumaczt *ngIf="!row.IsActive" class="d-block" [id]="'NieAktywne'" [typ]="'stale'" [tresci]="tresci">
              </app-tlumacz> -->
            <span *ngIf="row.IsTechnologyError" id="technologyerror" class="d-block"
              [translation]="TranslationEnum.Translation">{{'technologyerror'|translate}}</span>
            <!--<app-tlumaczt *ngIf="row.IsTechnologyError" class="d-block" [id]="'TechnologyError'" [typ]="'stale'"
                [tresci]="tresci"></app-tlumacz> -->
            <span *ngIf="row.ProformaFormat !== undefined && row.OrderStatusId === 1" id="zamów_w_płatnościach"
              class="d-block" [translation]="TranslationEnum.Translation">
              {{'zamów_w_płatnościach'|translate}}</span>
            <!--<app-tlumaczt *ngIf="row.ProformaFormat !== undefined && row.OrderStatusId === 1" class="d-block"
                [id]="'Zamów w płatnościach'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
          </ng-container>
        </td>
      </ng-container>

      <!-- ProductionNumber Column -->
      <ng-container matColumnDef="ProductionNumber">
        <th mat-header-cell *matHeaderCellDef mat-sort-header id="numer_produkcyjny"
          [translation]="TranslationEnum.Translation">
          {{'numer_produkcyjny'|translate}}
          <!--<app-tlumaczt [id]="'Numer_produkcyjny'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
        </th>
        <td mat-cell *matCellDef="let row"> {{row.ProductionNumber}} </td>
      </ng-container>

      <!-- OrderDate Column -->
      <ng-container matColumnDef="OrderDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header id="data_opracowania"
          [translation]="TranslationEnum.Translation">
          {{ 'data_opracowania'| translate}}
          <!--<app-tlumaczt [id]="'Data_opracowania'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
        </th>
        <td mat-cell *matCellDef="let row"> {{row.OrderDate | date:'yyyy-MM-dd H:mm:ss'}} </td>
      </ng-container>
      <!-- OrderDate Column -->
      <ng-container matColumnDef="OrderDateCreate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header id="data_otwarcia_zamowienia"
          [translation]="TranslationEnum.Translation">
          {{ 'data_otwarcia_zamowienia'| translate}}
          <!--<app-tlumaczt [id]="'Data_opracowania'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
        </th>
        <td mat-cell *matCellDef="let row"> {{row.OrderDateCreate | date:'yyyy-MM-dd H:mm:ss'}} </td>
      </ng-container>

      <!-- OrderingName Column -->
      <ng-container matColumnDef="OrderingName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header id="kto_opracował"
          [translation]="TranslationEnum.Translation">
          {{'kto_opracował'|translate}}
          <!--<app-tlumaczt [id]="'Kto_opracował'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
        </th>
        <td mat-cell *matCellDef="let row"> {{row.OrderingName}} </td>
      </ng-container>

      <!-- ProcessingSeltDate Column -->
      <ng-container matColumnDef="ProcessingSeltDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header id="data_zamówienia"
          [translation]="TranslationEnum.Translation">
          {{'data_zamówienia'|translate}}
          <!--<app-tlumaczt [id]="'Data_zamówienia'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
        </th>
        <td mat-cell *matCellDef="let row"> {{row.ProcessingSeltDate | date:'yyyy-MM-dd H:mm:ss'}} </td>
      </ng-container>

      <!-- ProcessingSeltName Column -->
      <ng-container matColumnDef="ProcessingSeltName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header id="kto_zamówił"
          [translation]="TranslationEnum.Translation">
          {{'kto_zamówił'|translate}}
          <!--<app-tlumaczt [id]="'Kto_zamówił'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
        </th>
        <td mat-cell *matCellDef="let row"> {{row.ProcessingSeltName}} </td>
      </ng-container>

      <!-- ProductionDate Column -->
      <ng-container matColumnDef="ProductionDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header id="termin_zak"
          [translation]="TranslationEnum.Translation">
          {{'termin_zak'|translate}}
          <!--<app-tlumaczt [id]="'Termin_zak'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
        </th>
        <td mat-cell *matCellDef="let row"> {{row.ProductionDate | date:'yyyy-MM-dd'}} </td>
      </ng-container>

      <!-- Edit Column -->
      <ng-container matColumnDef="Edit">
        <th mat-header-cell *matHeaderCellDef class=" w-75p">
          <span *ngIf="status === StatusEnum.Opracowywane" id="edytuj" class="d-block"
            [translation]="TranslationEnum.Translation">
            {{'edytuj'|translate}}
          </span>
          <!--<app-tlumaczt *ngIf="status === statusEnum.Opracowywane" class="d-block" [id]="'Edytuj'" [typ]="'stale'"
              [tresci]="tresci"></app-tlumacz> -->
          <span id="kopiuj" class="d-block" [translation]="TranslationEnum.Translation">
            {{'kopiuj'|translate}}
          </span>
          <span id="podglad" class="d-block" [translation]="TranslationEnum.Translation">
            {{'podglad'|translate}}
          </span>
          <!--<app-tlumaczt class="d-block " [id]="'Kopiuj'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
        </th>
        <td mat-cell *matCellDef="let row">
          <div class="buttons_box">
            <button
              *ngIf="row.IsEdit !== false && !this.User.IsTrader"
              mat-icon-button color="accent" (click)="routeToKindoperOrder(KindOper.Edit, row)">
              <mat-icon>create</mat-icon>
            </button>
            <button class="NewOption"
            *ngIf="row.IsEdit !== false && this.User.IsTrader"
            mat-icon-button color="accent" (click)="routeToKindoperOrder2(KindOper.Edit, row)">
            <mat-icon>create</mat-icon>
            <div *ngIf="false" class="experimental">experimental</div>
          </button>
            
          </div>
          <div class="buttons_box">
            <button *ngIf="row.IsCopy !== false && !this.User.IsTrader" mat-icon-button
              color="accent" (click)="routeToKindoperOrder(KindOper.Copy, row)">
              <mat-icon>content_copy</mat-icon>
            </button>
            <button class="NewOption" *ngIf="row.IsCopy !== false && this.User.IsTrader" mat-icon-button
              color="accent" (click)="routeToKindoperOrder2(KindOper.Copy, row)">
              <mat-icon>content_copy</mat-icon>
              <div *ngIf="false" class="experimental">experimental</div>
            </button>
          </div>
          <div class="buttons_box">
            <button mat-icon-button color="accent" *ngIf="!this.User.IsTrader" (click)="routeToKindoperOrder(KindOper.Preview, row)">
              <mat-icon>remove_red_eye</mat-icon>
            </button>
            <button class="NewOption" *ngIf="this.User.IsTrader" mat-icon-button
              color="accent" (click)="routeToKindoperOrder2(KindOper.Preview, row)">
              <mat-icon>remove_red_eye</mat-icon>
              <div *ngIf="false" class="experimental">experimental</div>
            </button>
          </div>
          
        </td>
      </ng-container>
      <ng-container matColumnDef="Parent">
        <th mat-header-cell *matHeaderCellDef class=" w-75p">
          <span id="powiazane_zlecenia" class="d-block" [translation]="TranslationEnum.Translation">
            {{'powiazane_zlecenia'|translate}}
          </span>
        </th>
        <td mat-cell *matCellDef="let row">
          <button class="parent_pow" *ngIf="row.ParentId != null && row.Parent != null && row.Parent.IsEdit !== false && this.User.IsTrader" mat-icon-button
          color="accent" title="Edytuj zlecenie powiązane {{row.Parent.B2BId}}"  (click)="routeToKindoperOrder2(KindOper.Edit, row.Parent)"> 
          <div class="parent_edit"></div>
        </button>
        <button class="parent_pow" *ngIf="row.ParentId != null && row.Parent != null && row.Parent.IsEdit !== false && !this.User.IsTrader" mat-icon-button
          color="accent" title="Edytuj zlecenie powiązane {{row.Parent.B2BId}}"  (click)="routeToKindoperOrder(KindOper.Edit, row.Parent)"> 
          <div class="parent_edit"></div>
        </button>
        <button class="parent_pow" *ngIf="row.ParentId != null" mat-icon-button
          color="accent" title="Zobacz zlecenie powiązane {{row.Parent.B2BId}}" (click)="routeToKindoperOrder(KindOper.Preview, row.Parent)"> 
          
          <div class="parent_view"></div>
        </button>
        <button class="child_pow" *ngIf="row.Childrens != null && row.Childrens.length > 0" mat-icon-button
          color="accent" title="Zobacz zlecenia powiązane ({{row.Childrens.length}})" (click) = "openChildrenDialog(row.Childrens)"> 
          
          <div class="child_view"></div>
        </button>
        
        </td>
      </ng-container>

      <!-- Note Column -->
      <ng-container matColumnDef="Notes">
        <th mat-header-cell *matHeaderCellDef class=" w-75p" id="notatki" [translation]="TranslationEnum.Translation">
          {{'notatki'|translate}}
          <!--<app-tlumaczt [id]="'Notatki'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
        </th>
        <td mat-cell *matCellDef="let row">
          <button mat-icon-button *ngIf="status === StatusEnum.Opracowywane" [ngStyle]="{ color: row.Note !== undefinded && row.Note !== ''? 'green' : '#7d7d7d' }"
            (click)="openOrderNoteDialog(row);">
            <mat-icon>sticky_note_2</mat-icon>
          </button>
        </td>
      </ng-container>

      <!-- Packages Column -->
      <ng-container matColumnDef="Packages">
        <th mat-header-cell *matHeaderCellDef id="paczki" [translation]="TranslationEnum.Translation">
          {{'paczki'|translate}}
          <!--<app-tlumaczt [id]="'Paczki'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
        </th>
        <td mat-cell *matCellDef="let row">
          
          <button *ngIf="row.IsPackages" mat-icon-button color="accent"
            (click)="openPackagesDialog(row.ProductionNumber)">
            <mat-icon>view_in_ar</mat-icon>
          </button>
          
          <button class = "paczkiprowadnice" *ngIf="row.WydrukPaczkiProwadnice" mat-icon-button color="accent"
            (click)="openProwadnice(row.ProductionNumber)">
          </button>
          <button class= "paczkiblachy" *ngIf="row.WydrukPaczkiBlacha" mat-icon-button color="accent"
            (click)="openBlacha(row.ProductionNumber)">
          </button>
        </td>
      </ng-container>

      <!-- OrderEstimate Column -->
      <ng-container matColumnDef="OrderEstimate">
        <th mat-header-cell *matHeaderCellDef>
          <span id="zamów" [translation]="TranslationEnum.Translation">{{'zamów'|translate}}</span>
          <!--<app-tlumaczt [id]="'zamów'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> --><br />
          <span id="wyceń" [translation]="TranslationEnum.Translation">{{'wyceń'|translate}}</span>
          <!--<app-tlumaczt [id]="'Wyceń'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> --><br />
          <span *ngIf="status === StatusEnum.Opracowywane && User.IsTrader" id="przelicz_wycene" [translation]="TranslationEnum.Translation">{{'przelicz_wycene'|translate}}</span>
        </th>
        <td mat-cell *matCellDef="let row">
          <ng-container *ngIf="!row.IsTechnologyError && row.IsOrder">
            <button mat-flat-button
              *ngIf="(!row.ForVerification || row.OrderStatusId === OrderStatusEnum.accepted) && row.ProformaFormat === undefined"
              id="zamów" class="btn-sm w-100 d-block mb-2" [translation]="TranslationEnum.Translation" color="primary"
              (click)="checkOrderAvailability(ModalForEnum.OrderMany, row.Id)">
              {{'zamów'|translate}} 
              <!--<app-tlumaczt [id]="'zamów'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
            </button>
            <button mat-flat-button *ngIf="row.ForVerification  &&row.OrderStatusId !== OrderStatusEnum.accepted"
              id="przekazdoweryfikacji" class="btn-sm w-100 d-block mb-2" [translation]="TranslationEnum.Translation"
              color="primary" (click)="submitOrderForVerificationBySelt(row.Id)">
              {{'przekazdoweryfikacji'|translate}}
              <!--<app-tlumaczt [id]="'PrzekazDoWeryfikacji'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
            </button>
          </ng-container>
          <button mat-flat-button id="wyceń" class="btn-sm w-100 d-block mb-2"
            [translation]="TranslationEnum.Translation" color="primary" (click)="openValuationDialog(row)">
            {{'wyceń'|translate}}
            <!--<app-tlumaczt [id]="'Wyceń'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
          </button>
          <button mat-flat-button *ngIf="status === StatusEnum.Opracowywane && User.IsTrader" id="przelicz_wycene" class="btn-sm w-100 d-block mb-2"
            [translation]="TranslationEnum.Translation" color="primary" onclick="document.body.style.cursor = 'wait'" (click)="calculateValuation(row.Id)">
            {{'przelicz_wycene'|translate}}
            <!--<app-tlumaczt [id]="'Wyceń'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
          </button>
        </td>
      </ng-container>

      <!-- Printout Column -->
      <ng-container matColumnDef="Printout">
        <th mat-header-cell *matHeaderCellDef id="wydruk" [translation]="TranslationEnum.Translation">
          {{'wydruk'|translate}}
          <!--<app-tlumaczt [id]="'wydruk'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
        </th>
        <td mat-cell *matCellDef="let row">
          <ng-container *ngIf="!row.IsTechnologyError">
            <a mat-flat-button id="specyfikacja" class="btn-sm w-100 d-block mb-2"
              [translation]="TranslationEnum.Translation" color="primary" [hidden]="row.IsTechnologyError"
              [href]="tresci.server_url + '/api/OrderRegister/GetSpecificationPrintout?guid=' + row.GuidId + '&culture=' + culture"
              target="_blank">
              {{'specyfikacja'|translate}}
              <!--<app-tlumaczt [id]="'Specyfikacja'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
            </a>
            <a mat-flat-button *ngIf="row.OrderStatusId !== 15" id="wycena" class="btn-sm w-100 d-block mb-2" [translation]="TranslationEnum.Translation"
              color="primary" [hidden]="row.IsTechnologyError"
              [href]="tresci.server_url + '/api/OrderRegister/GetValuationPrintout?guid=' + row.GuidId + '&culture=' + culture"
              target="_blank">
              {{'wycena'|translate}}
              <!--<app-tlumaczt [id]="'wycena'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
            </a>
            <button mat-flat-button id="mail" class="btn-sm w-100 d-block mb-2"
              [translation]="TranslationEnum.Translation" color="primary"
              (click)="openSendMailWithOrderDialog(row.Id, row.B2BId, row.Email)">
              {{'mail'|translate}}
              <!--<app-tlumaczt [id]="'mail'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
            </button>
          </ng-container>
        </td>
      </ng-container>

      <!-- Recalculate Column -->
      <ng-container matColumnDef="Recalculate">
        <th mat-header-cell *matHeaderCellDef>
          <span *ngIf="tresci.user.IsGenerateValuation === true" id="przelicz"
            [translation]="TranslationEnum.Translation">{{'przelicz'|translate}}</span>
          <!--<app-tlumaczDodajt *ngIf="tresci.user.IsGenerateValuation === true" [id]="'Przelicz'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
        </th>
        <td mat-cell *matCellDef="let row">
          <button mat-icon-button color="accent"
            *ngIf="!row.IsTechnologyError && tresci.user.IsGenerateValuation === true"
            (click)="recalculateEstimate(row.Id)">
            <mat-icon>request_quote</mat-icon>
          </button>
        </td>
      </ng-container>

      <!-- Remove Column -->
      <ng-container matColumnDef="Remove">
        <th mat-header-cell *matHeaderCellDef id="usunzam" [translation]="TranslationEnum.Translation">
          {{'usunzam'|translate}}
          <!--<app-tlumaczt [id]="'UsunZam'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
        </th>
        <td mat-cell *matCellDef="let row">
          <button mat-icon-button color="accent" *ngIf="row.IsToRemove " (click)="removeOrders(row.Id)">
            <mat-icon>delete_outline</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="DisplayedColumns;"></tr>
      <tr mat-row
        ngClass="row-{{OrderStatusEnum[row.OrderStatusId]}}-active-{{row.IsActive}}-error-{{row.IsTechnologyError}}"
        *matRowDef="let row; columns: DisplayedColumns;">
      </tr>

      <!-- Row shown when there is no matching data. -->
      <tr class="mat-row bg-white" *matNoDataRow>
        <td colspan="4" id="brak_danych_do_wyświetlenia" class="mat-cell" [translation]="TranslationEnum.Translation">
          {{'brak_danych_do_wyświetlenia'|translate}}
          <!--<app-tlumaczt [id]="'Brak danych do wyświetlenia'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
        </td>
      </tr>
    </table>
  </div>

  <!-- Paginator -->
  <mat-paginator [length]="ResultsLength" [pageSize]="10" [pageSizeOptions]="[10, 20, 50, 100, 200]"
    showFirstLastButtons></mat-paginator>

</div>

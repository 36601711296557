<h2 mat-dialog-title id="zlecenia_powiazane" [translation]="TranslationEnum.TranslationForm">
    {{'zlecenia_powiazane'|translate}}
</h2>


<div class="ramka_scroll">
    <table mat-table [dataSource]="orders" matSort matSortActive="B2BId" matSortDisableClear matSortDirection="desc">

        <ng-container matColumnDef="B2BId">
            <th mat-header-cell *matHeaderCellDef  id="b2bid" [translation]="TranslationEnum.Translation">
              {{'b2bid'|translate}}
              <!--<app-tlumaczt [id]="'B2Bid'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
            </th>
            <td mat-cell *matCellDef="let row"> {{row.B2BId}} </td>
        </ng-container>
        <ng-container matColumnDef="Produkt">
            <th mat-header-cell *matHeaderCellDef mat-sort-header id="produkt" [translation]="TranslationEnum.Translation">
              {{ 'produkt'|translate}}
              <!--<app-tlumaczt [id]="'Produkt'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
            </th>
            <td mat-cell *matCellDef="let row" id="produkt_{{row.Produkt}}" [translation]="TranslationEnum.Translation">
              {{'produkt_' + row.Produkt|translate}}
              <!--<app-tlumaczt [id]="'produkt_' + row.Product" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
            </td>
          </ng-container>
          <ng-container matColumnDef="System">
            <th mat-header-cell *matHeaderCellDef mat-sort-header id="system" [translation]="TranslationEnum.Translation">
              {{ 'system'| translate}}
            </th>
            <td mat-cell *matCellDef="let row" id="system_{{row.System}}" [translation]="TranslationEnum.Translation">
              {{('system_' + row.System)|translate}}
            </td>
          </ng-container>
          <ng-container matColumnDef="Quantity">
          <th mat-header-cell *matHeaderCellDef mat-sort-header id="ilość" [translation]="TranslationEnum.Translation">
            {{'ilość'|translate}}
            <!--<app-tlumaczt [id]="'Ilość'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
          </th>
          <td mat-cell *matCellDef="let row">{{row.Quantity}}</td>
        </ng-container>
        <ng-container matColumnDef="Description">
            <th mat-header-cell *matHeaderCellDef mat-sort-header id="uwagi" [translation]="TranslationEnum.Translation">
              {{'uwagi'|translate}}
              <!--<app-tlumaczt [id]="'Uwagi'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
            </th>
            <td mat-cell *matCellDef="let row">
              <mat-icon *ngIf="row.FileForVerification" style="transform: rotate(90deg); cursor: pointer;"
                (click)="openAttachmentDialog(row.Id)">attachment</mat-icon>
              {{row.Description}}
            </td>
        </ng-container>
        <ng-container matColumnDef="ValueNetto">
            <th mat-header-cell *matHeaderCellDef mat-sort-header id="cena_razem"
              [translation]="TranslationEnum.Translation">
              {{'cena_razem'|translate}}
              <!--<app-tlumaczt [id]="'Cena_razem'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
            </th>
            <td mat-cell *matCellDef="let row" class="cena" style="color: #cd0000">
              <ng-container *ngIf="row.ValueNetto !== null && row.Currency !== null && row.ValueNetto > 0">
                {{row.ValueNetto | PriceFormat}}&nbsp;
                {{row.Currency}}
                <!--<app-tlumaczt [id]="row.Currency" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> --><br />
                {{row.ValueBrutto | PriceFormat}}&nbsp;
                {{row.Currency}}
                <!--<app-tlumaczt [id]="row.Currency" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
              </ng-container>
              <ng-container *ngIf="row.ValueNetto == 0">
                ---
              </ng-container>
            </td>
          </ng-container>
          <ng-container matColumnDef="DeliveryCostNetto">
            <th mat-header-cell *matHeaderCellDef mat-sort-header id="cena_dostawy"
              [translation]="TranslationEnum.Translation">
              {{ 'cena_dostawy'| translate}}
              <!--<app-tlumaczt [id]="'Cena_dostawy'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
            </th>
            <td mat-cell *matCellDef="let row" class="cena" style="color: #cd0000">
              <ng-container *ngIf="row.DeliveryCostBrutto !== null && row.Currency !== null && row.DeliveryCostBrutto > 0">
                {{row.DeliveryCostNetto | PriceFormat}}&nbsp;
                {{row.Currency}}
                <!--<app-tlumaczt [id]="row.Currency" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> --><br />
                {{row.DeliveryCostBrutto | PriceFormat}}&nbsp;
                {{row.Currency}}
                <!--<app-tlumaczt [id]="row.Currency" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
              </ng-container>
              <ng-container *ngIf="row.DeliveryCostBrutto == 0">
                ---
              </ng-container>
            </td>
          </ng-container>
          <ng-container matColumnDef="OrderStatusName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header id="status" [translation]="TranslationEnum.Translation">
              {{ 'status'| translate}}
              <!--<app-tlumaczt [id]="'Status'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
            </th>
            <td mat-cell *matCellDef="let row">
              <ng-container *ngIf="row.OrderStatusId !== null">
                <span id="{{row.OrderStatusName}}" class="d-block" [translation]="TranslationEnum.Translation">{{
                  row.OrderStatusName|translate}}</span>
                <!--<app-tlumaczt  [id]="row.OrderStatusName" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
                <span *ngIf="!row.IsActive" id="nieaktywne" class="d-block" [translation]="TranslationEnum.Translation">
                  {{'nieaktywne'|translate}}</span>
                <!--<app-tlumaczt *ngIf="!row.IsActive" class="d-block" [id]="'NieAktywne'" [typ]="'stale'" [tresci]="tresci">
                  </app-tlumacz> -->
                <span *ngIf="row.IsTechnologyError" id="technologyerror" class="d-block"
                  [translation]="TranslationEnum.Translation">{{'technologyerror'|translate}}</span>
                <!--<app-tlumaczt *ngIf="row.IsTechnologyError" class="d-block" [id]="'TechnologyError'" [typ]="'stale'"
                    [tresci]="tresci"></app-tlumacz> -->
                <span *ngIf="row.ProformaFormat !== undefined && row.OrderStatusId === 1" id="zamów_w_płatnościach"
                  class="d-block" [translation]="TranslationEnum.Translation">
                  {{'zamów_w_płatnościach'|translate}}</span>
                <!--<app-tlumaczt *ngIf="row.ProformaFormat !== undefined && row.OrderStatusId === 1" class="d-block"
                    [id]="'Zamów w płatnościach'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
              </ng-container>
            </td>
          </ng-container>
          <ng-container matColumnDef="Edit">
            <th mat-header-cell *matHeaderCellDef class=" w-75p">
              <span id="edytuj" class="d-block"
                [translation]="TranslationEnum.Translation">
                {{'edytuj'|translate}}
              </span>
              <!--<app-tlumaczt *ngIf="status === statusEnum.Opracowywane" class="d-block" [id]="'Edytuj'" [typ]="'stale'"
                  [tresci]="tresci"></app-tlumacz> -->
              <span id="podglad" class="d-block" [translation]="TranslationEnum.Translation">
                {{'podglad'|translate}}
              </span>
              <!--<app-tlumaczt class="d-block " [id]="'Kopiuj'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
            </th>
            <td mat-cell *matCellDef="let row">
              <div class="buttons_box1">
               
                <button class="NewOption" *ngIf = "row.OrderStatusId == 1 && row.IsEdit !== false && !(row.IsTrader == true && IsTrader == false)" mat-icon-button
                  color="accent" (click)="routeToKindoperOrder(KindOper.Edit, row)">
                  <mat-icon>create</mat-icon>
                </button>
                <button class="NewOption" mat-icon-button
                  color="accent" (click)="routeToKindoperOrder(KindOper.Preview, row)">
                  <mat-icon>remove_red_eye</mat-icon>
                </button>
                
              </div>
            </td>
          </ng-container>


          <ng-container matColumnDef="OrderDate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header id="data_opracowania"
              [translation]="TranslationEnum.Translation">
              {{ 'data_opracowania'| translate}}
              <!--<app-tlumaczt [id]="'Data_opracowania'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
            </th>
            <td mat-cell *matCellDef="let row"> {{row.OrderDate | date:'yyyy-MM-dd H:mm:ss'}} </td>
          </ng-container>
          <ng-container matColumnDef="OrderDateCreate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header id="data_otwarcia_zamowienia"
              [translation]="TranslationEnum.Translation">
              {{ 'data_otwarcia_zamowienia'| translate}}
              <!--<app-tlumaczt [id]="'Data_opracowania'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
            </th>
            <td mat-cell *matCellDef="let row"> {{row.OrderDateCreate | date:'yyyy-MM-dd H:mm:ss'}} </td>
          </ng-container>
          <ng-container matColumnDef="OrderingName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header id="kto_opracował"
              [translation]="TranslationEnum.Translation">
              {{'kto_opracował'|translate}}
              <!--<app-tlumaczt [id]="'Kto_opracował'" [typ]="'stale'" [tresci]="tresci"></app-tlumacz> -->
            </th>
            <td mat-cell *matCellDef="let row"> {{row.OrderingName}} </td>
          </ng-container>



        <tr mat-header-row *matHeaderRowDef="DisplayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: DisplayedColumns;" class="aktywne_{{row.IsActive}}"></tr>
    
        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4" id="brak_danych_do_wyswietlenia" [translation]="TranslationEnum.TranslationForm">
            {{'brak_danych_do_wyswietlenia'|translate}}
          </td>
        </tr>
        </table>
</div>

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Control } from 'src/app/form-v2/form-model/Control';
import { FormDataV2 } from 'src/app/form-v2/form-model/FormDataV2';
import { DynamicFormComponent } from 'src/app/new-form/initial-form/dynamic-form/dynamic-form.component';
import { TranslationEnum } from 'src/app/shared/enums/translation-enum';
import { Parameter } from 'src/app/shared/models/form-model/models/form-controlls.model';

@Component({
  selector: 'app-parentmod-control',
  templateUrl: './parentmod-control.component.html',
  styleUrls: ['./parentmod-control.component.scss']
})
export class ParentmodControlComponent implements OnInit {
  public TranslationEnum = TranslationEnum;
  public control:Control;
  public FormData: FormDataV2;
  public list:number;
  public parametry = [];
  public wyswietlane = [];
  
  constructor(public dialogRef: MatDialogRef<DynamicFormComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.control = data.control;
    this.FormData = data.formData;
    this.list = data.list;
  }

  ngOnInit(): void {
    console.log("pp", this.FormData.parent_Parameters);
    this.FormData.parent_Parameters.forEach(element => {
      while(element.Module >= this.parametry.length){
        this.parametry.push([]);
        this.wyswietlane.push([]);
      }
      if(element.ControlName == 'Number' || element.ControlName == 'Select' || element.ControlName == 'Check' || element.ControlName == 'Text' ){
        if(element.DisplayValue != "" && element.DisplayValue != null){
          while(element.Position >= this.parametry[element.Module].length){
            this.parametry[element.Module].push([]);
          }
          
          this.parametry[element.Module][element.Position].push(element);
          if(element.Key == 'PozDotyczy'){
            this.wyswietlane[element.Module].push(element);
          }
          
        }
       
      }
      
    });
  }
  ustawMod(v){
    console.log("wybrano", v);
    var p = new Parameter();
    p.Disabled = false;
    p.DisplayValue = v;
    p.DisplayValueCs = v;
    p.DisplayValueDe = v;
    p.DisplayValueEn = v;
    p.DisplayValueFr = v;
    p.DisplayValueIt = v;
    p.DisplayValuePl = v;
    p.Key = this.control.KeyName;
    // p.Module = v.Module.valueOf();
    // p.Position = v.Position.valueOf();
    p.Type = this.control.ControlType;
    p.Value = v;
    p.IsPrint = false;
    
    this.control.wybrany = p;
    this.dialogRef.close();
    
  }

}

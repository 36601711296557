<h2 mat-dialog-title id="pozycja_do_wybrania" [translation]="TranslationEnum.TranslationForm">
    {{'pozycja_do_wybrania'|translate}}
</h2>

<div class="ramka_scroll">
    <div class="mod_box"  *ngFor="let poz of list"  (click) = "ustawPoz(poz)">
        <div class="numer_mod"><span class="zestaw" id="pozycja_numer" [translation]="TranslationEnum.TranslationForm">{{'pozycja_numer'|translate}}</span> {{poz}}</div>
        <div class="dane_box" *ngIf = "wyswietlane[poz].length > 0"><div class="prop"  *ngFor="let prop of wyswietlane[poz]"><span  id="label_{{prop.Key.toLowerCase()}}" [translation]="TranslationEnum.TranslationForm">{{('label_' + prop.Key.toLowerCase())|translate}}</span> = {{prop.DisplayValue}}</div> </div>
        
        <div id="wybierz" class="button" [translation]="TranslationEnum.Translation">
            {{'wybierz'|translate}}
          </div>
    </div>
</div>
<div mat-dialog-actions>
    <div class="w-100">
      <button mat-flat-button id="anuluj" class="btn-sm float-right" [translation]="TranslationEnum.Translation"
        color="primary" [mat-dialog-close]="true">
        {{'anuluj'|translate}}
      </button>
    </div>
  </div>
import { Parameter } from "src/app/shared/models/form-model/models/form-controlls.model";

export class FormDataSend {
    Parameters:Parameter[] = [];
    ParameterExtensions:any[] = []; 
    ContractorNumber: string;
    ProductPrefix: string;
    Culture:string;
    IsCache:boolean;
    DeliveryMethodId: string;
    DeliveryAddressId: number;
    Id?: number;
    TechnologyVersion: Number;
    IsTrader: boolean;
    IsComplaint: boolean;
    CopiedOrderId?: number;
    ComplaintId: Number;
    ComplaintNumber: string;
    ComplaintType: Number;
    ChargeableRepairsId: Number;
    ChargeableRepairsNumber: string;
    ParentId: Number;

    DeliveryAddress: string;
    DeliveryCity: string;
    DeliveryCountry: string;
    DeliveryZipCode: string;
    DeliveryZone: string;

    DeliveryMethod: string;
    constructor(){

    }
}
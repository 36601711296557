<h2 mat-dialog-title id="modul_do_wybrania" [translation]="TranslationEnum.TranslationForm">
    {{'modul_do_wybrania'|translate}}
</h2>

    
<div class="ramka_scroll">
    <div class="mod_box"  *ngFor="let mod of list"  (click) = "ustawMod(mod)">
        <div class="numer_mod"><span class="zestaw" id="zestaw" [translation]="TranslationEnum.TranslationForm">{{'zestaw'|translate}}</span> {{mod}}</div>
        <div class="ilosc_box">{{parametry[mod].length - 1}} <span class="zestaw" id="pozycji" [translation]="TranslationEnum.TranslationForm">{{'pozycji'|translate}}</span> (<span class="prop"  *ngFor="let prop of parametry[mod][0]"><span  id="label_{{prop.Key.toLowerCase()}}" [translation]="TranslationEnum.TranslationForm">{{('label_' + prop.Key.toLowerCase())|translate}}</span> = {{prop.DisplayValue}},</span>)</div>
        <div class="dane_box" *ngIf = "wyswietlane[mod].length > 0"><div class="prop"  *ngFor="let prop of wyswietlane[mod]"><span  id="label_{{prop.Key.toLowerCase()}}" [translation]="TranslationEnum.TranslationForm">{{('label_' + prop.Key.toLowerCase())|translate}}</span> = {{prop.DisplayValue}}</div> </div>
        <div id="wybierz" class="button" [translation]="TranslationEnum.Translation">
            {{'wybierz'|translate}}
          </div>
    </div>
</div>
<div mat-dialog-actions>
    <div class="w-100">
      <button mat-flat-button id="anuluj" class="btn-sm float-right" [translation]="TranslationEnum.Translation"
        color="primary" [mat-dialog-close]="true">
        {{'anuluj'|translate}}
      </button>
    </div>
  </div>
<h2 mat-dialog-title id="zlecenia_do_wybrania" [translation]="TranslationEnum.TranslationForm">
    {{'zlecenia_do_wybrania'|translate}}
</h2>
<div class="box_out2" *ngIf = "this.loading">
    <mat-spinner ></mat-spinner>
</div>
<div class="box_out" *ngIf = "!this.loading">
    <div class="filter_box">
  
    
        <div class="input_filter">
          <input (keyup)="applyFilter($event.target.value)">
          
        </div>
        <div class="box_status">
          <ng-container *ngFor="let l of lista">
              <div class="button_stat now_{{selected_id == sprawdzListe(l)}}" (click) = "wybierzListe(l)"  [translation]="TranslationEnum.TranslationForm">
                  {{l|translate}}
              </div>
          </ng-container>
        </div>
        
      </div>
    
    
    <div class="ramka_scroll">
       
        
        <div class = "parent_box" *ngIf = "Data != null">
            <table mat-table [dataSource]="Data.Orders" class="mat-elevation-z8">
                <ng-container matColumnDef="B2BId">
                    <th mat-header-cell *matHeaderCellDef> B2BId </th>
                    <td mat-cell *matCellDef="let element"  (click) = "wybierz(element.Id)">{{element.B2BId}} </td>
                </ng-container>
                <ng-container matColumnDef="ValueNetto">
                    <th mat-header-cell *matHeaderCellDef> ValueNetto </th>
                    <td mat-cell *matCellDef="let element" (click) = "wybierz(element.Id)">{{element.ValueNetto}} </td>
                </ng-container>
                <ng-container matColumnDef="ValueBrutto">
                    <th mat-header-cell *matHeaderCellDef> ValueBrutto </th>
                    <td mat-cell *matCellDef="let element" (click) = "wybierz(element.Id)">{{element.ValueBrutto}} </td>
                </ng-container>
                <ng-container matColumnDef="Currency">
                    <th mat-header-cell *matHeaderCellDef> Currency </th>
                    <td mat-cell *matCellDef="let element" (click) = "wybierz(element.Id)">{{element.Currency}} </td>
                </ng-container>
                <ng-container matColumnDef="Quantity">
                    <th mat-header-cell *matHeaderCellDef> Quantity </th>
                    <td mat-cell *matCellDef="let element" (click) = "wybierz(element.Id)">{{element.Quantity}} </td>
                </ng-container>
                <ng-container matColumnDef="OrderDateCreate">
                    <th mat-header-cell *matHeaderCellDef> OrderDateCreate </th>
                    <td mat-cell *matCellDef="let element" (click) = "wybierz(element.Id)">{{element.OrderDateCreate|date:'yyyy-MM-dd'}} </td>
                </ng-container>
                  
    
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table> 
    
    
          
    
            <div  *ngIf = "false">
                <ng-container *ngFor="let zlec of Data.Orders">
                    <div (click) = "wybierz(zlec.Id)">
                        {{zlec.B2BId}} {{zlec.ValueNetto}} {{zlec.ValueBrutto}} {{zlec.Currency}} {{zlec.Quantity}} {{zlec.OrderDateCreate|date:'yyyy-MM-dd'}}
                    </div>
                </ng-container>
            </div>
            
              
            
        </div>
    </div>
    
    
    <div mat-dialog-actions>
        <div class="w-100">
          <button mat-flat-button id="akceptuj" class="btn-sm float-right" [translation]="TranslationEnum.Translation"
            color="primary" [mat-dialog-close]="true">
            {{'anuluj'|translate}}
          </button>
        </div>
      </div>
    
    
</div>



<ng-container *ngIf = "DevMode">
    
    <div class="DevMode_box">
        <div class="DevIcon">
            
        </div>
        <div class="DevMode">
        <span class="pole_devK">
            <span class="opis_k">Typ kontrolki</span>
            <span class="wartosc_k">{{control.ControlType}}</span>
        </span>
        <span class="pole_devK">
            <span class="opis_k">Nazwa kontrolki</span>
            <span class="wartosc_k">{{control.KeyName}}</span>
        </span>
        <span class="pole_devK">
            <span class="wartosc_k" (click) = "otworzDev()"><div class="info_dev"></div></span>
            <span class="pole_kontrolka_dev">
                
                
            </span>
        </span>
        <div class="wybierz_wartosc">
            <div class="wyboerz_wartosc_tresc">wybierz wartość:</div>
            <div class="opcje_Wartosci"> (<span *ngFor="let v of control.Values; let kk = index;" class="opcja" (click) = "zmienWartoscAdmin(v.Key, false)">{{v.Key}} - </span>)</div>
        </div>
    </div>
</div>
</ng-container>

<div class="ustawienie">
        
        <container-element [ngSwitch]="control.ControlType">
            <!-- the same view can be shown in more than one case -->
            <ng-container *ngSwitchCase="'NewLine'"><div class="NewLine"></div></ng-container>
            
            <ng-container *ngSwitchCase="'Label'">
                <div class="formatuj">
                    <div class="ng"><span class="Label_box">
                        <span  id="{{control.TranslateCode.toLowerCase()}}" [translation]="TranslationEnum.TranslationForm">{{('' + control.TranslateCode.toLowerCase())|translate}}</span>
                        <app-information [label]="control.TranslateCode" [notAffectOnOtherControl]='control.notAffectToOtherControls' [fileInfoId]="fileInfoId"></app-information>
                    </span></div>
                    <div class="kon" class="Label"  [style.background-color]="control.BackgroundColour" [style.color]="control.ForegrandColour">
                        <div *ngIf="CzyLiczba(control.wybrany.DisplayValue)">{{control.wybrany.DisplayValue}}</div>
                        <div *ngIf="!CzyLiczba(control.wybrany.DisplayValue)"><span  id="{{control.TranslateCode.toLowerCase()}}_value" [translation]="TranslationEnum.TranslationForm">{{(control.TranslateCode.toLowerCase() + '_value')|translate}}</span></div>
                    </div>
                </div>
                
                
            </ng-container>
            <ng-container *ngSwitchCase="'Check'"><div class="Check poprawna{{control.poprawne}}">

                <input type = "checkbox" [(ngModel)]="checked" (change) = "changeCheck($event)" [disabled] = "!GrupaKrokow.CzyAktywny(i)">
                <span class="check_text"><span  id="label_{{control.TranslateCode.toLowerCase()}}" [translation]="TranslationEnum.TranslationForm">{{('label_' + control.TranslateCode.toLowerCase())|translate}}</span>
                <app-information [label]="control.TranslateCode" [notAffectOnOtherControl]='control.notAffectToOtherControls' [fileInfoId]="fileInfoId"></app-information></span>
                
                
            </div></ng-container>
            <ng-container *ngSwitchCase="'Module'" ><div class="Module poprawna{{control.poprawne}}">
                <div class="ng"><span class="Label_box">
                    <span  id="{{control.TranslateCode.toLowerCase()}}" [translation]="TranslationEnum.TranslationForm">{{('' + control.TranslateCode.toLowerCase())|translate}}</span>
                    <app-information [label]="control.TranslateCode" [notAffectOnOtherControl]='control.notAffectToOtherControls' [fileInfoId]="fileInfoId"></app-information>
                </span></div>

                    <div class="pola wyboru">
                        <div class="modul_wiersz" *ngFor="let kolumna of module.matrix; let x = index">
                            <ng-container *ngFor="let komorka of kolumna; let y = index">
                                <div *ngIf = "(komorka || module.czySasiad(x, y)) && GrupaKrokow.CzyAktywny(i)" class="modul_komorka komorka_{{komorka}}" 
                                (click)="SetModule(y,x)">
                                    <span *ngIf="komorka">M{{module.getName(y,x)}} <span class="rodzaj_mod">({{module.getRodzaj(x, y)}})</span></span>
                                </div>
                                <div *ngIf = "(komorka || module.czySasiad(x, y)) && !GrupaKrokow.CzyAktywny(i)" class="modul_komorka komorka_{{komorka}}" >
                                    <span *ngIf="komorka">M{{module.getName(y,x)}} <span class="rodzaj_mod">({{module.getRodzaj(x, y)}})</span></span>
                                </div>
                                <div *ngIf = "!komorka && !module.czySasiad(x, y)" class="modul_komorka komorka_szara komorka_{{komorka}}">
                                    <span *ngIf="komorka"></span>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                    
                    <div class="wybrane_mod">wybrane moduły: {{module.getDisplay()}}</div>
                    <div class="wybrane_mod"> {{control.wybrany.Value}}</div>
                  
            </div></ng-container>

        
        <ng-container *ngSwitchCase="'Pergola'">
            <div class="pergola_out poprawna{{control.poprawne}}">
                <div class="Pergola" id="Pergola{{control.pergola.random}}">
                    <div class="pergola_pole">
                        <div class="pergola_zew"  [style.width.px]="control.pergola.getWidth()"   [style.height.px]="control.pergola.getHeight()" >
                            <div class="sciany">
                                <div class="sciana scianaA" *ngIf = "control.pergola.scianaA"></div>
                                <div class="sciana scianaB" *ngIf = "control.pergola.scianaB"></div>
                                <div class="sciana scianaC" *ngIf = "control.pergola.scianaC"></div>
                                <div class="sciana scianaD" *ngIf = "control.pergola.scianaD"></div>
                            </div>
                            <div class="wymiarx">
                                <div class="wymiarx2">
                                    <div class="wymiar">{{control.pergola.szerokosc}} mm</div>
                                </div>
                            </div>
                            <div class="wymiary">
                                <div class="wymiary2">
                                    <div class="wymiary2-2"  [style.bottom.px]="control.pergola.getLeft()">{{control.pergola.glebokosc}} mm</div>
                                </div>
                            </div>
                            <div class = "pole_slupy">
        
                                <ng-container *ngFor="let slup of control.pergola.slupy; let s = index;">
                                    <div class="slup blad_{{slup.blad || slup.blad_nachodzi}}"  cdkDrag  cdkDragBoundary=".pergola_zew" [style.width.px]="slup.width_skala" [style.height.px]="slup.height_skala"  [cdkDragFreeDragPosition]="slup.dragPosition" (dblclick) = "otworzSlup(s)" (cdkDragStarted)="onDragStart($event, slup, s)"  (cdkDragEnded)="onDragEnd($event, slup, s)">
                                        
                                        <div *ngIf = "!slup.przenosze && slup.pozy != 0  && slup.jakaStrona(control.pergola) == 1" class="wymiar1"  [style.height.px]="slup.wymiar_y * slup.skala + 1"  [style.bottom.px]="slup.getHeight() - 2">
                                            <div class= "wymiar_liniax"></div>
                                            <div class= "wymiar_wew">{{slup.wymiar_y}} mm </div>
                                        </div>
                                        <div *ngIf = "!slup.przenosze && slup.pozy != 0 && slup.jakaStrona(control.pergola) == 3" class="wymiar3"  [style.height.px]="slup.wymiar_y * slup.skala + 1"  [style.bottom.px]="slup.getHeight() - 2">
                                            <div class= "wymiar_liniax3"></div>
                                            <div class= "wymiar_wew3">{{slup.wymiar_y}} mm</div>
                                        </div>
                                        <div *ngIf = "!slup.przenosze && slup.pozy == 0 && slup.jakaStrona(control.pergola) == 3"  class="wymiar2"  [style.width.px]="slup.wymiar_x * slup.skala + 1"  [style.right.px]="slup.getWidth() - 2">
                                            <div class= "wymiar_liniax2"></div>
                                            <div class= "wymiar_wew2">{{slup.wymiar_x}} mm</div>
                                        </div>
                                        <div *ngIf = "!slup.przenosze && slup.jakaStrona(control.pergola) == 2" class="wymiar2"  [style.width.px]="slup.wymiar_x * slup.skala + 1"  [style.right.px]="slup.getWidth() - 2">
                                            <div class= "wymiar_liniax2"></div>
                                            <div class= "wymiar_wew2">{{slup.wymiar_x}} mm</div>
                                        </div>
                                        <div *ngIf = "!slup.przenosze && slup.jakaStrona(control.pergola) == 4" class="wymiar4"  [style.width.px]="slup.wymiar_x * slup.skala + 1"  [style.right.px]="slup.getWidth() - 2">
                                            <div class= "wymiar_liniax4"></div>
                                            <div class= "wymiar_wew4">{{slup.wymiar_x}} mm</div>
                                        </div>
                                        <div *ngIf = "!slup.przenosze && slup.pozy == control.pergola.glebokosc - control.pergola.szerokosc_slupa && slup.jakaStrona(control.pergola) == 3" class="wymiar4"  [style.width.px]="slup.wymiar_x * slup.skala + 1"  [style.right.px]="slup.getWidth() - 2">
                                            <div class= "wymiar_liniax4"></div>
                                            <div class= "wymiar_wew4">{{slup.wymiar_x}} mm</div>
                                        </div>
                                    </div>
                                    
                                </ng-container>
                                
                            </div>
                            <div class="pergola_wew"   [style.left.px]="control.pergola.getColumnHeight()"   [style.top.px]="control.pergola.getColumnHeight()" [style.width.px]="control.pergola.getInnerWidth()"   [style.height.px]="control.pergola.getInnerHeight()">
                                
                            </div>
                        </div>
                       
                    </div>
                        
                        
        
                        <div class="wybrane_mod"> {{control.wybrany.Value}}</div>
                      
                </div>

                <div class="legenda">
                    <h2 id = "legenda"  [translation]="TranslationEnum.TranslationForm">{{'legenda'|translate}}</h2>
                    <div class="opcja" ><div class="opcja_icon"><div class="slup_w"></div></div><div class="opcja_opis">- 
                        <span id = "slup"  [translation]="TranslationEnum.TranslationForm">{{'slup'|translate}}</span>
                        <button  mat-flat-button  type="button" color="accent" id="dodaj_slup" class="btn-sm float-right"
                        [translation]="TranslationEnum.TranslationForm" (click) = "control.pergola.dodajSlup()">
                        {{'dodaj_slup'|translate}}
                        </button>
                
                    </div></div>
                  

                    <div class="opcja"><div class="opcja_icon"><div class="slup_w blad_true"></div></div><div class="opcja_opis">- <span id = "blad_slup"  [translation]="TranslationEnum.TranslationForm">{{'blad_slup'|translate}}</span></div></div>
                    <div class="opcja"><div class="opcja_icon"><div class="sciana_icon"></div></div><div class="opcja_opis">- <span id = "sciana_text"  [translation]="TranslationEnum.TranslationForm">{{'sciana_text'|translate}}</span></div></div>
                    
                    <div class="opcja2" >
                        <button  mat-flat-button  type="button" color="accent" id="print_img_to_jpg" class="btn-sm float-right"
                        [translation]="TranslationEnum.TranslationForm" (click) = "generateImage()">
                        {{'print_img_to_jpg'|translate}}
                        </button>
                
                    </div>
                    
                    <div id="podpowiedz" [translation]="TranslationEnum.TranslationForm">
                        {{'podpowiedz'|translate}}
                    </div>
                </div>

            </div>
            
          
    </ng-container>

            
    
            
            <ng-container *ngSwitchCase="'Text'"><div class="Text poprawna{{control.poprawne}} formatuj">
                <span class="Label_box ng">
                    <span  id="label_{{control.TranslateCode.toLowerCase()}}" [translation]="TranslationEnum.TranslationForm">{{('label_' + control.TranslateCode.toLowerCase())|translate}}</span>
                    <app-information [label]="control.TranslateCode" [notAffectOnOtherControl]='control.notAffectToOtherControls' [fileInfoId]="fileInfoId"></app-information>
                </span>
               
                <div class="kon">
                    <input  name="parametr" (change) = "changeText(control)" [disabled] = "!GrupaKrokow.CzyAktywny(i)" [(ngModel)]="control.wybrany.Value">
                </div>
                        
                 
            </div></ng-container>
    
    
             
            <ng-container *ngSwitchCase="'Number'"><div class="Number poprawna{{control.poprawne}} formatuj">
                <span class="Label_box ng">
                    <span  id="label_{{control.TranslateCode.toLowerCase()}}" [translation]="TranslationEnum.TranslationForm">{{('label_' + control.TranslateCode.toLowerCase())|translate}}</span> ({{getMinNumber()}} - {{getMaxNumber()}})
                    <app-information [label]="control.TranslateCode" [notAffectOnOtherControl]='control.notAffectToOtherControls' [fileInfoId]="fileInfoId"></app-information>
                </span>
                <div class="kon">
                    <input  name="parametr" (change) = "changeNumber(control)" [disabled] = "!GrupaKrokow.CzyAktywny(i)" [(ngModel)]="control.wybrany.Value">
                </div>
                        
                 
            </div></ng-container>
    
    
            <ng-container *ngSwitchCase="'VarianticVisualization'"><div class="VarianticVisualization poprawna{{control.poprawne}}">
                
            </div></ng-container>
            
            <ng-container *ngSwitchCase="'Render'"><div class="Render poprawna{{control.poprawne}} formatuj">
                
                <div class="kon" (click) = "zmienionoParametr()">
                    <img [src]="src"  [style.width.px]="control.PictureWidth">
                </div>
                        
                 
            </div></ng-container>
            
            
            
            <ng-container *ngSwitchCase="'CheckNumber'"><div class="CheckNumber poprawna{{control.poprawne}} formatuj">
                <span class="Label_box ng">
                    <span  id="label_{{control.TranslateCode.toLowerCase()}}" [translation]="TranslationEnum.TranslationForm">{{('label_' + control.TranslateCode.toLowerCase())|translate}}</span> ({{getMinNumber()}} - {{getMaxNumber()}})
                    <app-information [label]="control.TranslateCode" [notAffectOnOtherControl]='control.notAffectToOtherControls' [fileInfoId]="fileInfoId"></app-information>
                </span>
                
                        
                 
                        <div class="CheckSelect_box kon">
                            <div class="CheckSelect_check">
                                <input type = "checkbox" [(ngModel)]="checked" (change) = "changeCheckNumber($event)" [disabled] = "!GrupaKrokow.CzyAktywny(i)">
                            </div>
                            <div class="CheckSelect_selkect">
                                <input  name="parametr" (change) = "changeNumber(control)" [disabled] = "!GrupaKrokow.CzyAktywny(i) && checked" [(ngModel)]="control.wybrany.Value">
                            </div>
                        </div>
            </div></ng-container>
    
            <ng-container *ngSwitchCase="'CheckSelect'"><div class="CheckSelect poprawna{{control.poprawne}} czyaktywny{{czyAktywnyCheckSelect()}} formatuj">
                <span class="Label_box ng">
                    <span  id="label_{{control.TranslateCode.toLowerCase()}}" [translation]="TranslationEnum.TranslationForm">{{('label_' + control.TranslateCode.toLowerCase())|translate}}</span>
                    <app-information [label]="control.TranslateCode" [notAffectOnOtherControl]='control.notAffectToOtherControls' [fileInfoId]="fileInfoId"></app-information>
                </span>
                <div class="CheckSelect_box con">
                    <div class="CheckSelect_check">
                        <input type = "checkbox" [(ngModel)]="checked" (change) = "changeCheckSelect($event)" [disabled] = "!GrupaKrokow.CzyAktywny(i)">
                    </div>
                    <div class="CheckSelect_selkect">
                        <form [formGroup]="form">
                            <mat-form-field>
                                <mat-select (selectionChange) = "changeSelect($event)" formControlName="state">
                                    <ng-container  *ngFor="let v of control.Values; let kk = index;">
                                        <mat-option [value]="v.Key">{{v.DisplayValue}}</mat-option>
                                    </ng-container>
                                  
                                </mat-select>
                              </mat-form-field>
                        </form>
                    </div>
                </div>
                
               
                
                
            </div></ng-container>

            <ng-container *ngSwitchCase="'CheckSelectMultiColumn'"><div class="CheckSelect poprawna{{control.poprawne}} czyaktywny{{czyAktywnyCheckSelect()}} formatuj">
                <span class="Label_box ng">
                    <span  id="label_{{control.TranslateCode.toLowerCase()}}" [translation]="TranslationEnum.TranslationForm">{{('label_' + control.TranslateCode.toLowerCase())|translate}}</span>
                    <app-information [label]="control.TranslateCode" [notAffectOnOtherControl]='control.notAffectToOtherControls' [fileInfoId]="fileInfoId"></app-information>
                </span>
                <div class="CheckSelect_box con">
                    <div class="CheckSelect_check">
                        <input type = "checkbox" [(ngModel)]="checked" (change) = "changeCheckSelectMulti($event)" [disabled] = "!GrupaKrokow.CzyAktywny(i)">
                    </div>
                    
                <div class="pole_wartosc_kim2" (click) = "otworzSelectMultiColumnCheck()"><span class="kolor"  *ngIf = "checked && control.wybrany.Kolor != null && control.wybrany.Kolor != ''" [style.background-color]="control.wybrany.Kolor" ></span>{{control.wybrany.DisplayValue}}</div>
            
                </div>
                
               
                
                
            </div></ng-container>


            
            <ng-container *ngSwitchCase="'Select'"><div class="Select poprawna{{control.poprawne}} czyaktywny{{czyAktywnySelect()}} formatuj">
                <span class="Label_box ng">
                    <span id="label_{{control.TranslateCode.toLowerCase()}}" [translation]="TranslationEnum.TranslationForm">{{('label_' + control.TranslateCode.toLowerCase())|translate}}</span>
                    <app-information [label]="control.TranslateCode" [notAffectOnOtherControl]='control.notAffectToOtherControls' [fileInfoId]="fileInfoId"></app-information>
                </span>
                <div class="kon">
                    <form [formGroup]="form">
                        <mat-form-field>
                            <mat-select (selectionChange) = "changeSelect($event)" formControlName="state" width = "auto">
                                <ng-container  *ngFor="let v of control.Values; let kk = index;">
                                    <mat-option [value]="v.Key">{{v.DisplayValue}}</mat-option>
                                </ng-container>
                            
                            </mat-select>
                        </mat-form-field>
                    </form>
                </div>
                
                
            </div></ng-container>
            <ng-container *ngSwitchCase="'RadioImg'"><div class="RadioImg poprawna{{control.poprawne}} formatuj">
                <span class="Label_box">
                    <span  id="label_{{control.TranslateCode.toLowerCase()}}" [translation]="TranslationEnum.TranslationForm">{{('label_' + control.TranslateCode.toLowerCase())|translate}}</span>
                    <app-information [label]="control.TranslateCode" [notAffectOnOtherControl]='control.notAffectToOtherControls' [fileInfoId]="fileInfoId"></app-information>
                </span>
                <div class="kon">
                    <div class="fotobox fotobox_{{control.Width}}">
                        <ng-container *ngFor="let v of control.Values; let kk = index;">
                            <span class="foto_control zaznaczony_{{v.Key == control.wybrany.Value}} Disabled_{{v.Disabled}}"  (click) = "zmienWartosc(v.Key, v.Disabled)">
                                <div class="ok"></div>
                                <img class="foto"  src="{{server_url}}//api/ActiveForm/GetIcon?code={{system}}_{{control.KeyName}}_{{v.Key}}" [style.width.px]="control.PictureWidth">
                                <div class="wartosc">{{v.DisplayValue}}</div>
                            </span>
                        </ng-container>
                    </div>
                </div>
                
            </div></ng-container>
            
            <ng-container *ngSwitchCase="'Pdf'"><div class="Pdf poprawna{{control.poprawne}} formatuj">
                <span class="Label_box ng">
                    <span  id="label_{{control.TranslateCode.toLowerCase()}}" [translation]="TranslationEnum.TranslationForm">{{('label_' + control.TranslateCode.toLowerCase())|translate}}</span>
                    <app-information [label]="control.TranslateCode" [notAffectOnOtherControl]='control.notAffectToOtherControls' [fileInfoId]="fileInfoId"></app-information>
                </span>
                <div class="kon">
                    <a href="{{control.DefaultValue}}" target = "_blank"><div class="Pdf_tlo" [style.width.px]="control.PictureWidth" [style.height.px]="control.PictureWidth"></div></a>
                </div>
                
            </div></ng-container>
         
            <ng-container *ngSwitchCase="'SelectImg'"><div class="SelectImg poprawna{{control.poprawne}} czyaktywny{{czyAktywnySelect()}} formatuj">
                <span class="Label_box ng">
                    <span  id="label_{{control.TranslateCode.toLowerCase()}}" [translation]="TranslationEnum.TranslationForm">{{('label_' + control.TranslateCode.toLowerCase())|translate}}</span>
                    <app-information [label]="control.TranslateCode" [notAffectOnOtherControl]='control.notAffectToOtherControls' [fileInfoId]="fileInfoId"></app-information>
                </span>
                <div class="kon">
                    <form [formGroup]="form">
                        <mat-form-field>
                            <mat-select (selectionChange) = "changeSelect($event)" formControlName="state">
                                <ng-container  *ngFor="let v of control.Values; let kk = index;">
                                    <mat-option [value]="v.Key"><div class="select_wybor"><img class="foto"  src="{{server_url}}//api/ActiveForm/GetIcon?code={{v.Key}}" [style.width.px]="control.PictureWidth"><sapn class="select_text">{{v.DisplayValue}}</sapn></div></mat-option>
                                </ng-container>
                            
                            </mat-select>
                        </mat-form-field>
                    </form>
                </div>
                
                
            </div></ng-container>
    
            
            <ng-container *ngSwitchCase="'Img'"><div class="Img poprawna{{control.poprawne}}">
                <ng-container *ngIf ="control.KeyName.includes('Rysunek')">
                    <img class="foto" src="{{server_url}}//api/ActiveForm/GetIcon?code={{control.DefaultValue}}"  [style.width.px]="control.PictureWidth" >
                </ng-container>
                <ng-container *ngIf ="!control.KeyName.includes('Rysunek')">
                    <button [disabled]="!GrupaKrokow.CzyAktywny(i)" mat-flat-button  type="button" color="accent" id="label_{{control.TranslateCode.toLowerCase()}}" class="btn-sm float-right"
                    [translation]="TranslationEnum.TranslationForm" (click)="openImgDialog()">
                    {{('label_'+ control.TranslateCode.toLowerCase())|translate}}
                  </button>
             
                    
                    <ng-template #imgDialog>
                    <div mat-dialog-content>
                        <img [src]="src">
                    </div>
                    </ng-template>
                </ng-container>
                  
            </div></ng-container>
            
            <ng-container *ngSwitchCase="'Kim'"><div class="Kim poprawna{{control.poprawne}} formatuj">
                <span class="Label_box ng">
                    <span  id="label_{{control.TranslateCode.toLowerCase()}}" [translation]="TranslationEnum.TranslationForm">{{('label_' + control.TranslateCode.toLowerCase())|translate}}</span>
                    <app-information [label]="control.TranslateCode" [notAffectOnOtherControl]='control.notAffectToOtherControls' [fileInfoId]="fileInfoId"></app-information>
                </span>
                <div class="pole_wartosc_kim kon" (click) = "otworzKim()">{{control.wybrany.Value}}</div>
            </div></ng-container>

            
            <ng-container *ngSwitchCase="'SelectMultiColumn'"><div class="SelectMultiColumn poprawna{{control.poprawne}} formatuj">
                <span class="Label_box ng">
                    <span  id="label_{{control.TranslateCode.toLowerCase()}}" [translation]="TranslationEnum.TranslationForm">{{('label_' + control.TranslateCode.toLowerCase())|translate}}</span>
                    <app-information [label]="control.TranslateCode" [notAffectOnOtherControl]='control.notAffectToOtherControls' [fileInfoId]="fileInfoId"></app-information>
                </span>
                <div class="pole_wartosc_kim kon" (click) = "otworzSelectMultiColumn()"><span class="kolor"  *ngIf = "control.wybrany.Kolor != null && control.wybrany.Kolor != ''" [style.background-color]="control.wybrany.Kolor" ></span>{{control.wybrany.DisplayValue}}</div>
            </div></ng-container>
            <ng-container *ngSwitchCase="'Fabric'"><div class="Fabric poprawna{{control.poprawne}} formatuj">
                <span class="Label_box ng">
                    <span  id="label_{{control.TranslateCode.toLowerCase()}}" [translation]="TranslationEnum.TranslationForm">{{('label_' + control.TranslateCode.toLowerCase())|translate}}</span>
                    <app-information [label]="control.TranslateCode" [notAffectOnOtherControl]='control.notAffectToOtherControls' [fileInfoId]="fileInfoId"></app-information>
                </span>
                <div class="pole_wartosc_kim kon" (click) = "otworzFabric()"><img *ngIf = "control.wybrany.Value != null && control.wybrany.Value != ''" src = "{{server_url}}//api/ActiveForm/GetIcon?code={{control.wybrany.Value}}" /> {{control.wybrany.DisplayValue}}</div>
            </div></ng-container>
            <ng-container *ngSwitchCase="'WyborParent'"><div class="WyborParent poprawna{{control.poprawne}} formatuj">
                <span class="Label_box ng">
                    <span  id="label_{{control.TranslateCode.toLowerCase()}}" [translation]="TranslationEnum.TranslationForm">{{('label_' + control.TranslateCode.toLowerCase())|translate}}</span>
                    <app-information [label]="control.TranslateCode" [notAffectOnOtherControl]='control.notAffectToOtherControls' [fileInfoId]="fileInfoId"></app-information>
                </span>
                <div class="pole_wartosc_kim kon" (click) = "otworzParent()">{{control.wybrany.DisplayValue}}</div>
            </div></ng-container>
            <ng-container *ngSwitchCase="'WyborParentModule'">
                <div *ngIf = "this.moduleList.length > 1" class="WyborParentModule poprawna{{control.poprawne}} formatuj">
                    <span class="Label_box ng">
                        <span  id="label_{{control.TranslateCode.toLowerCase()}}" [translation]="TranslationEnum.TranslationForm">{{('label_' + control.TranslateCode.toLowerCase())|translate}}</span>
                        <app-information [label]="control.TranslateCode" [notAffectOnOtherControl]='control.notAffectToOtherControls' [fileInfoId]="fileInfoId"></app-information>
                    </span>
                    <div class="pole_wartosc_kim kon" (click) = "otworzParentMod()">{{control.wybrany.DisplayValue}}</div>
                </div>
                <div *ngIf = "this.moduleList.length == 1" class="WyborParentModule poprawna{{control.poprawne}} formatuj">
                    <span class="Label_box ng">
                        <span  id="label_{{control.TranslateCode.toLowerCase()}}" [translation]="TranslationEnum.TranslationForm">{{('label_' + control.TranslateCode.toLowerCase())|translate}}</span>
                        <app-information [label]="control.TranslateCode" [notAffectOnOtherControl]='control.notAffectToOtherControls' [fileInfoId]="fileInfoId"></app-information>
                    </span>
                    <div class="pole_wartosc_kim kon">{{control.wybrany.DisplayValue}}</div>
                </div>
            </ng-container>
            <ng-container *ngSwitchCase="'WyborParentPosition'"><div class="WyborParentPosition poprawna{{control.poprawne}} formatuj">
                <span class="Label_box ng">
                    <span  id="label_{{control.TranslateCode.toLowerCase()}}" [translation]="TranslationEnum.TranslationForm">{{('label_' + control.TranslateCode.toLowerCase())|translate}}</span>
                    <app-information [label]="control.TranslateCode" [notAffectOnOtherControl]='control.notAffectToOtherControls' [fileInfoId]="fileInfoId"></app-information>
                </span>
                <div class="pole_wartosc_kim kon" (click) = "otworzParentPoz()">{{control.wybrany.DisplayValue}}</div>
            </div></ng-container>
            <ng-container *ngSwitchCase="'File'"><div class="File poprawna{{control.poprawne}} czyaktywny_{{GrupaKrokow.CzyAktywny(i)}} formatuj">
                <span class="Label_box ng">
                    <span  id="label_{{control.TranslateCode.toLowerCase()}}" [translation]="TranslationEnum.TranslationForm">{{('label_' + control.TranslateCode.toLowerCase())|translate}}</span>
                    <app-information [label]="control.TranslateCode" [notAffectOnOtherControl]='control.notAffectToOtherControls' [fileInfoId]="fileInfoId"></app-information>
                </span>
                <div class="file_box kon">
                    
                    <div class="pole_wartosc_kim">
                        <input   [disabled] = "!GrupaKrokow.CzyAktywny(i)"
                #input
                type="file"
                (change)="changeImg($event)"
                accept="image/png, image/jpeg, image/gif, image/bmp, application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/plain"
              />
                        {{control.wybrany.DisplayValue}} <div class="icon_upload"><mat-icon>file_upload</mat-icon></div></div><div *ngIf = "checked && fileExist()" class="file_view" (click) = "previewFile();"><mat-icon>remove_red_eye</mat-icon></div></div>
                
            </div></ng-container>
            <ng-container *ngSwitchCase="'CheckFile'"><div class="CheckFile poprawna{{control.poprawne}} czyaktywny_{{GrupaKrokow.CzyAktywny(i) && checked}} formatuj">
                <span class="Label_box ng">
                    <span  id="label_{{control.TranslateCode.toLowerCase()}}" [translation]="TranslationEnum.TranslationForm">{{('label_' + control.TranslateCode.toLowerCase())|translate}}</span>
                    <app-information [label]="control.TranslateCode" [notAffectOnOtherControl]='control.notAffectToOtherControls' [fileInfoId]="fileInfoId"></app-information>
                </span>
    
                <div class="CheckSelect_box kon">
                    <div class="CheckSelect_check">
                        <input type = "checkbox" [(ngModel)]="checked" (change) = "changeCheckFile($event)" [disabled] = "!GrupaKrokow.CzyAktywny(i)">
                    </div>
                    <div class="CheckSelect_selkect">
                        <div class="file_box">
                            <div class="pole_wartosc_kim">
                                <input  [disabled] = "!GrupaKrokow.CzyAktywny(i) && checked"
                #input
                type="file"
                (change)="changeImg($event)"
                accept="image/png, image/jpeg, image/gif, image/bmp, application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/plain"
              />
                                {{control.wybrany.DisplayValue}} <div class="icon_upload"><mat-icon>file_upload</mat-icon></div></div><div *ngIf = "checked && fileExist()" class="file_view" (click) = "previewFile();"><mat-icon>remove_red_eye</mat-icon></div></div>
                    </div>
                </div>
    
                
                
            </div></ng-container>
            <ng-container *ngSwitchDefault>
                <div class="kontrolki poprawna{{control.poprawne}} formatuj">
                    <span class="Label_box ng">
                        <span *ngIf = "control.ControlType != 'Hidden'">UWAGA - Kontrolka ({{control.ControlType}}) nie zaimplementowana, proszę zgłosić do działu wsparcia! - </span><span  id="label_{{control.TranslateCode.toLowerCase()}}" [translation]="TranslationEnum.TranslationForm">{{('label_' + control.TranslateCode.toLowerCase())|translate}}</span>
                        <app-information [label]="control.TranslateCode"  [notAffectOnOtherControl]='control.notAffectToOtherControls' [fileInfoId]="fileInfoId"></app-information>
                    </span>
                    <div class="pole_wartosc kon"><input name="parametr" (change) = "FormData.ZmianaParametru(control)" [disabled] = "!GrupaKrokow.CzyAktywny(i)" [(ngModel)]="control.wybrany.Value" /></div>
        
                </div>
            </ng-container>
        </container-element>
        <ng-container *ngIf = "DevMode">
            <span class="Wartosc_akt">wartość: {{control.wybrany.Value}} Wyświetla: {{control.wybrany.DisplayValue}}</span>
            
        </ng-container>
    

</div>
   
    

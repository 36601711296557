import { BroadcastService } from './../../shared/async-services/http/broadcast.service';
import { OrderEditDialogComponent } from './order-edit-dialog/order-edit-dialog.component';
import { OrderAttachmentDialogComponent } from './order-attachment-dialog/order-attachment-dialog.component';
import { DataSharingService } from './../../shared/service/data-sharing.service';
import { KindOper } from './../../shared/enums/kind-oper-enum';
import { UserPermission } from './../../shared/models/user/user-permission.model';
import { AuthorizationService } from 'src/app/module-login/authorization.service';
import { MailWithOrder } from './../../shared/models/order-register/order-list/mail-with-order.model';
import { SendMailWithOrderDialogComponent } from './send-mail-dialog/send-mail-with-order-dialog.component';
import { OrderPackageDialogComponent } from './order-package-dialog/order-package-dialog.component';
import { OrderStatusEnum } from './../../shared/enums/order-status-enum';
import { FormControl, FormGroup } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { SelectionModel } from '@angular/cdk/collections';
import { StatusEnum } from './../../shared/enums/status-enum';
import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild, AfterViewChecked, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { ModalForEnum } from 'src/app/shared/enums/modal-for-enum';
import { UniversalDialogComponent } from 'src/app/shared/components/universal-dialog/universal-dialog.component';
import { DialogData } from 'src/app/shared/models/dialog/dialog-data.model';
import { OrderWarningsDialogComponent } from 'src/app/module-order-register/order-list/order-warning-dialog/order-warnings-dialog.component';
import { OrderRegisterService } from '../order-register.service';
import { OrderNoteDialogComponent } from 'src/app/module-order-register/order-list/order-note-dialog/order-note-dialog.component';
import { merge, Observable, of as observableOf, Subscription } from 'rxjs';
import { catchError, concatMap, finalize, map, startWith, switchMap, takeWhile, tap } from 'rxjs/operators';
import { TresciService } from 'src/app/tresci.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { OrderList } from 'src/app/shared/models/order-register/order-list/order-list.model';
import { DateAdapter } from '@angular/material/core';
import { OrderEstimateDialogComponent } from './order-estimate-dialog/order-estimate-dialog.component';
import { TranslationEnum } from 'src/app/shared/enums/translation-enum';
import { Router } from '@angular/router';
import { User } from 'src/app/shared/models/user/user.model';
import { CookieService } from 'ngx-cookie-service';
import { PermissionEnum } from 'src/app/shared/enums/permission-enum';
import { TranslateService } from '@ngx-translate/core';
import { LangChangeEvent } from '@ngx-translate/core';
import { Contractor } from 'src/app/shared/models/user/contractor.model';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { ChildListComponent } from '../child-list/child-list.component';

class FilterCoulmn {
  Name: string;
  Translate: string;
}

@Component({
  selector: 'app-order-list',
  templateUrl: './order-list.component.html',
  styleUrls: ['./order-list.component.scss'],
})
export class OrderListComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  @Input() status: StatusEnum;
  @Input() dateFrom: string = null;
  @Input() dateTo: string = null;
  @Input() isMineOrders = false;
  @Input() refresh = false;
  @Input() ShowComplaints = false;

  @Output() filterChanged = new EventEmitter<{ value: string }>();
  @Output() dateChange = new EventEmitter<{ value: string }>();
  @Output() isMineOrdersChange = new EventEmitter<{ value: StatusEnum }>();
  @Output() refreshEmit = new EventEmitter<{}>();

  public TranslationEnum = TranslationEnum;
  public ModalForEnum = ModalForEnum;
  public OrderStatusEnum = OrderStatusEnum;
  public StatusEnum = StatusEnum;

  public DisplayedColumns: string[];
  public Data: OrderList[] = [];
  public Selection = new SelectionModel<OrderList>(true, []);
  public ResultsLength = 0;
  public IsLoadingResults = true;
  public IsRateLimitReached = false;
  public User: User;
  public EditingOrderspermission: UserPermission;
  public KindOper = KindOper;

  public culture: string;
  private subscriptions: Subscription[] = [];

  public FilterColumns: FilterCoulmn[] = [
    { Name: 'b2bid', Translate: 'b2bid' },
    { Name: 'produkt', Translate: 'produkt' },
    { Name: 'system', Translate: 'system' },
    { Name: 'applies', Translate: 'dotyczy' },
  ];
  public FilterColumn: string = this.FilterColumns[0].Name;
  public Range = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });

  private filterTerm = '';
  private contractorNumber = '';
  private broadcastErrorSubscription: Subscription;
  Loading: boolean = false;

  constructor(
    private _adapter: DateAdapter<any>,
    private orderRegisterService: OrderRegisterService,
    public dialog: MatDialog,
    public tresci: TresciService,
    private router: Router,
    private cookieService: CookieService,
    private authorizationService: AuthorizationService,
    private _changeDetectorRef: ChangeDetectorRef,
    translationService: TranslateService,
    private dataSharingService: DataSharingService,
    private broadcastService: BroadcastService,private httpClient: HttpClient
  ) {
    this.subscriptions.push(this.authorizationService.User.subscribe((user) => {
      this.User = user;
      this.EditingOrderspermission = this.authorizationService.hasPermission(PermissionEnum.EditingOrders);
    }));
    this.culture = window['culture'];
    this._adapter.setLocale(this.culture);
    
    this.subscriptions.push(translationService.onLangChange.subscribe((event: LangChangeEvent) => {
      this.culture = window['culture'];
      this._adapter.setLocale(this.culture);
      this._changeDetectorRef.detectChanges();
    }));

    const contractor = JSON.parse(sessionStorage.getItem('_slc')) as Contractor;

    this.contractorNumber = contractor.ContractorNumber;

  }

  ngOnInit() {
    if (this.status !== StatusEnum.Zakonczono) {
      this.dateFrom = null;
      this.dateTo = null;
    }
    this.Range.patchValue({
      start: this.dateFrom,
      end: this.dateTo,
    });

    this.subscriptions.push(this.broadcastErrorSubscription = this.broadcastService.ErrorHttp.asObservable().subscribe((x) => {
      if (x !== null) {
        let dialogRef: MatDialogRef<UniversalDialogComponent, any>;
        const data: DialogData = {
          title: 'blad',
          content: x,
          answerOk: true,
        };
        dialogRef = this.dialog.open(UniversalDialogComponent, { data: data });
      }
    }));

    this.changeDisplayColumns();
  }

  ngAfterViewInit(): void {
    this.subscriptions.push(this.dataSharingService.contractorChanged.subscribe((x) => {
      if (x) {
        const contractor = JSON.parse(sessionStorage.getItem('_slc')) as Contractor;
        this.contractorNumber = contractor.ContractorNumber;
        this.refreshEmit.emit({
          value: true,
        });
      }
    }));

    this.subscriptions.push(this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0)));
    this.labelsMatPaginator();

    if (localStorage.getItem('goToPage') !== 'no') {
      this.paginator.pageIndex = this.orderRegisterService.goToPage;
    }

    this.subscriptions.push(merge(this.sort.sortChange, this.paginator.page, this.filterChanged, this.refreshEmit, this.dateChange)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.IsLoadingResults = true;
          this.Selection.clear();
          return this.orderRegisterService.getOrders(
            this.contractorNumber,
            this.status,
            this.FilterColumn,
            this.filterTerm,
            this.sort.active,
            this.sort.direction,
            this.paginator.pageIndex,
            this.paginator.pageSize,
            this.dateFrom,
            this.dateTo,
            this.isMineOrders,
            this.ShowComplaints
          );
        }),
        map((data) => {
          this.IsLoadingResults = false;
          this.IsRateLimitReached = false;
          this.ResultsLength = data.TotalCount;
          return data.Orders;
        }),
        catchError(() => {
          this.IsLoadingResults = false;
          this.IsRateLimitReached = true;
          return observableOf([]);
        })
      )
      .subscribe((data) => {
        this.Data = data;
      }));

    localStorage.setItem('goToPage', 'no');
  }

  ngOnDestroy(): void {
    this.broadcastErrorSubscription.unsubscribe();
    this.subscriptions.forEach(e => !e.closed ? e.unsubscribe() : null);
    this.subscriptions= [];
    /*Object.keys(this).forEach(key => {
      this[key] = null;
    });*/
  }

  ngOnChanges() {
    this.refreshEmit.emit({
      value: true,
    });
  }

  applyFilter(event: any): void {
    this.filterTerm = event.value.trim().toLowerCase();
    this.paginator.pageIndex = 0;
    this.filterChanged.emit({
      value: this.filterTerm,
    });
  }

  dateRangeChange(): void {
    if (this.Range.controls['start'].value && this.Range.controls['end'].value) {
      this.dateFrom = this.Range.controls['start'].value.toISOString().slice(0, 10);
      this.dateTo = this.Range.controls['end'].value.toISOString().slice(0, 10);
      this.dateChange.emit();
    }
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.Selection.selected.length;
    const numRows = this.Data.length;
    return numSelected >= numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    if (this.isAllSelected()) {
      this.Selection.clear();
    } else {
      this.Selection.clear();
      //this.Data.forEach((row) => {if(row.System != 'sb350'){this.Selection.select(row)}});
      this.Data.forEach((row) => this.Selection.select(row));
    }
  }

  /** Select at least one order. Used in collective buttons. */
  selectAtLeastOneDialog() {
    let dialogRef: MatDialogRef<UniversalDialogComponent, any>;
    const data: DialogData = {
      title: '',
      content: 'zaznacz_chociaz_jedna_pozycje',
      answerOk: true,
    };
    dialogRef = this.dialog.open(UniversalDialogComponent, { data: data });
  }

  selectedIsWithError(content: string) {
    let dialogRef: MatDialogRef<UniversalDialogComponent, any>;
    const data: DialogData = {
      title: '',
      content: content,
      answerOk: true,
    };
    dialogRef = this.dialog.open(UniversalDialogComponent, { data: data });
  }

  /** Return list of ids that are selected on grid or one id from order with activated button. */
  selectedIdsList(id?: number): number[] {
    const ids = [];
    if (id !== undefined) {
      ids.push(id);
    } else if (this.Selection.selected.length > 0) {
      this.Selection.selected.forEach((x) => {
        ids.push(x.Id);
      });
    }
    return ids;
  }

  cancelOrders(id?: number) {
    if (id === undefined && this.Selection.selected.length <= 0) {
      this.selectAtLeastOneDialog();
    } else {
      let dialogRef: MatDialogRef<UniversalDialogComponent, any>;
      const data: DialogData = {
        title: '',
        content: 'czy_na_pewno_chcesz_anulowac_pozycje',
        answerOk: false,
      };
      dialogRef = this.dialog.open(UniversalDialogComponent, { data: data });
      this.subscriptions.push(dialogRef.afterClosed().subscribe((result) => {
        if (result === true) {
          this.IsLoadingResults = true;
          const ids = this.selectedIdsList(id);
          if (ids.length > 0) {
            this.subscriptions.push(this.orderRegisterService.cancelOrders(ids).subscribe(() => {
              this.refreshEmit.emit({
                value: true,
              });
            }));
          }
        }
      }));
    }
  }

  removeOrders(id?: number) {
    if (id === undefined && this.Selection.selected.length <= 0) {
      this.selectAtLeastOneDialog();
    } else {
      let dialogRef: MatDialogRef<UniversalDialogComponent, any>;
      const data: DialogData = {
        title: '',
        content: 'czy_na_pewno_chcesz_usunac_pozycje',
        answerOk: false,
      };
      dialogRef = this.dialog.open(UniversalDialogComponent, { data: data });
      this.subscriptions.push(dialogRef.afterClosed().subscribe((result) => {
        if (result === true) {
          this.IsLoadingResults = true;
          const ids = this.selectedIdsList(id);
          if (ids.length > 0) {
            this.subscriptions.push(this.orderRegisterService.removeOrders(ids).subscribe(() => {
              this.refreshEmit.emit({
                value: true,
              });
            }));
          }
        }
      }));
    }
  }

  /** Open modalBox that contain information about avabality of order. Used with order and genearte proforma button. */
  checkOrderAvailability(modalForEnum: ModalForEnum, Id?: number) {
    let dialogRef: MatDialogRef<OrderWarningsDialogComponent, any>;
 
    if (Id === undefined && this.Selection.selected.length <= 0) {
      this.selectAtLeastOneDialog();
    } else if (this.Selection.selected.find((x) => x.IsActive === false  ) !== undefined) {
      this.selectedIsWithError('chociaz_jedna_pozycja_jest_nieaktywna');
    }else if (this.Selection.selected.find((x) =>  x.IsTechnologyError === true ) !== undefined) {
      this.selectedIsWithError('chociaz_jedna_pozycja_jest_bledna');
    }  else if (this.Selection.selected.find((x) => (x.ForVerification === true && x.IsVerified === false)) !== undefined) {
      this.selectedIsWithError('chociaz_jedna_pozycja_jest_nie_zweryfikowana');
    }  else {
      const ids = this.selectedIdsList(Id);
      if (ids.length > 0) {
        this.subscriptions.push(this.tresci
          .checkingAvailability(ids)
          .pipe( 
            tap((data) => {
              dialogRef = this.dialog.open(OrderWarningsDialogComponent, {
                data: { tableData: data, modalForEnum: modalForEnum },
                minWidth: '1200px',
              });
              this.subscriptions.push(dialogRef.afterClosed().subscribe((result) => {
                this.refreshEmit.emit({
                  value: true,
                });
              }));
            })
          )
          .subscribe());
      }
    }
  }

  private changeDisplayColumns() {
    switch (this.status) {
      case StatusEnum.Opracowywane:
        this.DisplayedColumns = [
          'select',
          'B2BId',
          'ProformaFormat',
          'Produkt',
          'System',
          'Quantity',
          'Applies',
          'Description',
          'ValueNetto',
          'DeliveryCostNetto',
          'OrderStatusName',
          'OrderDate',
          'OrderDateCreate',
          'OrderingName',
          'Edit',
          'Parent',
          'Notes',
          'OrderEstimate',
          'Printout',
          'Recalculate',
          'Remove',
        ];
        break;
      case StatusEnum.Zamowiono:
        this.DisplayedColumns = [
          'B2BId',
          'ProformaFormat',
          'Produkt',
          'System',
          'Quantity',
          'Applies',
          'Description',
          'ValueNetto',
          'DeliveryCostNetto',
          'OrderStatusName',
          'ProductionNumber',
          'ProcessingSeltDate',
          'OrderingName',
          'ProcessingSeltName',
          'ProductionDate',
          'Edit',
          'Parent',
          'Notes',
          'Packages',
          'OrderEstimate',
          'Printout',
          'Recalculate',
        ];
        break;
      case StatusEnum.Realizacja:
        this.DisplayedColumns = [
          'B2BId',
          'ProformaFormat',
          'Produkt',
          'System',
          'Quantity',
          'Applies',
          'Description',
          'ValueNetto',
          'DeliveryCostNetto',
          'OrderStatusName',
          'ProductionNumber',
          'ProcessingSeltDate',
          'OrderingName',
          'ProcessingSeltName',
          'ProductionDate',
          'Edit',
          'Parent',
          'Notes',
          'Packages',
          'OrderEstimate',
          'Printout',
          'Recalculate',
        ];
        break;
      case StatusEnum.Wstrzymano:
        this.DisplayedColumns = [
          'B2BId',
          'ProformaFormat',
          'Produkt',
          'System',
          'Quantity',
          'Applies',
          'Description',
          'ValueNetto',
          'DeliveryCostNetto',
          'OrderStatusName',
          'ProductionNumber',
          'OrderDate',
          'OrderDateCreate',
          'OrderingName',
          'Edit',
          'Parent',
          'Notes',
          'OrderEstimate',
          'Printout',
          'Recalculate',
        ];
        break;
      case StatusEnum.Zakonczono:
        this.DisplayedColumns = [
          'B2BId',
          'ProformaFormat',
          'Produkt',
          'System',
          'Quantity',
          'Applies',
          'Description',
          'ValueNetto',
          'DeliveryCostNetto',
          'OrderStatusName',
          'ProductionNumber',
          'ProcessingSeltDate',
          'OrderingName',
          'OrderDate',
          'OrderDateCreate',
          'ProcessingSeltName',
          'ProductionDate',
          'Edit',
          'Parent',
          'Notes',
          'Packages',
          'OrderEstimate',
          'Printout',
          'Recalculate',
        ];
        break;
      case StatusEnum.Anulowano:
        this.DisplayedColumns = [
          'B2BId',
          'ProformaFormat',
          'Produkt',
          'System',
          'Quantity',
          'Applies',
          'Description',
          'ValueNetto',
          'DeliveryCostNetto',
          'OrderStatusName',
          'ProductionNumber',
          'OrderingName',
          'ProcessingSeltDate',
          'ProcessingSeltName',
          'ProductionDate',
          'Edit',
          'Parent',
          'Notes',
          'OrderEstimate',
          'Printout',
          'Recalculate',
        ];
        break;
    }
  }

  openAttachmentDialog(id: number): void {
    let dialogRef: MatDialogRef<OrderAttachmentDialogComponent, any>;
    this.subscriptions.push(this.orderRegisterService
      .getAttachments(id)
      .pipe(
        tap((data) => {
          // console.log("id data", id, data);
          dialogRef = this.dialog.open(OrderAttachmentDialogComponent, { data: data, minWidth: '1200px' });
        })
      )
      .subscribe());
  }

  openOrderNoteDialog(order) {
    const dialogRef = this.dialog.open(OrderNoteDialogComponent, {
      width: '550px',
      data: order,
    });
    this.subscriptions.push(dialogRef.afterClosed().subscribe(() => {
      this.refreshEmit.emit({value: true});
    }));
  }
  openPackagesDialog(productionNumber: string) {
    let dialogRef: MatDialogRef<OrderPackageDialogComponent, any>;
    this.subscriptions.push(this.orderRegisterService
      .getPackages(productionNumber)
      .pipe(
        tap((data) => {
          dialogRef = this.dialog.open(OrderPackageDialogComponent, { data: data, minWidth: '1200px' });
        })
      )
      .subscribe());
  }
  openProwadniceDialog(productionNumber: string) {
    let dialogRef: MatDialogRef<OrderPackageDialogComponent, any>;
    this.subscriptions.push(this.orderRegisterService
      .getPackages(productionNumber)
      .pipe(
        tap((data) => {
          dialogRef = this.dialog.open(OrderPackageDialogComponent, { data: data, minWidth: '1200px' });
        })
      )
      .subscribe());
  }
  openBlacha(productionNumber: string) {
    this.Loading = true;
    this.subscriptions.push(this.GetFileBlacha(this.culture, productionNumber).subscribe((data) => {

      // this.blob = new Blob([data], {type: 'application/x'});
    
      var downloadURL = window.URL.createObjectURL(data);
      //window.open(downloadURL,'_blank');
      
      var fileLink = document.createElement('a');
      fileLink.href = downloadURL;
      fileLink.target = "_blank";  
      //fileLink.download = plik.Name;
      fileLink.click();
    }));
  }
  openProwadnice(productionNumber: string) {
    this.Loading = true;
    this.subscriptions.push(this.GetFileGuide(this.culture, productionNumber).subscribe((data) => {

      // this.blob = new Blob([data], {type: 'application/x'});
    
      var downloadURL = window.URL.createObjectURL(data);
      //window.open(downloadURL,'_blank');
      
      var fileLink = document.createElement('a');
      fileLink.href = downloadURL;
      fileLink.target = "_blank";  
      //fileLink.download = plik.Name;
      fileLink.click();
    }));
  }
  public GetFileGuide(language: string, productionNumber:string): Observable<any> {
    // var body:PostBody = new PostBody();
     // body.ContractorNumber = ContractorNumber;
     const httpOptions = {
      responseType: 'blob' as 'json'
    };
    this.Loading = false;
     const data = {
      ProductionNumber: productionNumber,
    };
    return this.httpClient.post(
      'api/OrderRegister/GetGuidePackagePrint?culture='+language,
      data, httpOptions
    );
   }
   public GetFileBlacha(language: string, productionNumber:string): Observable<any> {
     // var body:PostBody = new PostBody();
      // body.ContractorNumber = ContractorNumber;
      const httpOptions = {
       responseType: 'blob' as 'json'
     };
     this.Loading = false;
      const data = {
       ProductionNumber: productionNumber,
     };
     return this.httpClient.post(
       'api/OrderRegister/GetMetalSheetPackagePrint?culture='+language,
       data, httpOptions
     );
    }
  submitOrderForVerificationBySelt(id: number) {
    this.subscriptions.push(this.orderRegisterService.submitOrderForVerificationBySelt(id).subscribe((x) => {
      if (x[0].WarningList.length > 0) {
        let dialogRef: MatDialogRef<UniversalDialogComponent, any>;
        const stringList: string[] = [];
        for (const warning of x[0].WarningList) {
          const a = warning.ParameterName + ' ' + warning.ParameterValue;
          stringList.push(a);
        }
        const data: DialogData = {
          title: '',
          content: 'brak_elektryki_w_magazynie',
          additionalContent: `${stringList.join()}`,
          answerOk: true,
        };
        dialogRef = this.dialog.open(UniversalDialogComponent, { data: data });
      } else {
        this.refreshEmit.emit({
          value: true,
        });
      }
    }));
  }

  calculateValuation(id: number) {
    this.subscriptions.push(this.orderRegisterService
      .calculateValuation(id)
      .pipe( 
        finalize(() => {
          let dialogRef: MatDialogRef<UniversalDialogComponent, any>;
          const data: DialogData = {
            title: '',
            content: 'wycena_zostala_przeliczona',
            answerOk: true,
          };
          dialogRef = this.dialog.open(UniversalDialogComponent, { data: data });
          document.body.style.cursor = 'default';

          ////////////////////////////////////////////////
          // this.Data = [];

          this.subscriptions.push(merge(this.sort.sortChange, this.paginator.page, this.filterChanged, this.refreshEmit, this.dateChange)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.IsLoadingResults = true;
          this.Selection.clear();
          return this.orderRegisterService.getOrders(
            this.contractorNumber,
            this.status,
            this.FilterColumn,
            this.filterTerm,
            this.sort.active,
            this.sort.direction,
            this.paginator.pageIndex,
            this.paginator.pageSize,
            this.dateFrom,
            this.dateTo,
            this.isMineOrders,
            this.ShowComplaints
          );
        }),
        map((data) => {
          this.IsLoadingResults = false;
          this.IsRateLimitReached = false;
          this.ResultsLength = data.TotalCount;
          return data.Orders;
        }),
        catchError(() => {
          this.IsLoadingResults = false;
          this.IsRateLimitReached = true;
          return observableOf([]);
        })
      )
      .subscribe((data) => {
        this.Data = data;
      }));


          ////////////////////////////////////////////////
          
        })
      )
      .subscribe());
  }

  routeToKindoperOrder(kindOper: KindOper, row) {
    if (!this.User.IsTrader || row.IsTrader || kindOper === KindOper.Copy) {
      this.router.navigate([`Order/${KindOper[kindOper]}/${row.Prefix}/${row.Id}`]);
    } else if (this.User.IsTrader && row.IsTrader === false && kindOper !== KindOper.Preview) {
      let dialogRef: MatDialogRef<OrderEditDialogComponent, any>;
      dialogRef = this.dialog.open(OrderEditDialogComponent, { data: { row: row, kindOper: kindOper, version: 'Order' } });
    } else if (kindOper === KindOper.Preview) {
      if (this.User.IsTrader && row.IsTrader === false) {
        kindOper = KindOper.PreviewClient;
        this.router.navigate([`Order/${KindOper[kindOper]}/${row.Prefix}/${row.Id}`]);
      } else {
        this.router.navigate([`Order/${KindOper[kindOper]}/${row.Prefix}/${row.Id}`]);
      }
    }
    this.orderRegisterService.goToPage = this.paginator.pageIndex;
  }
  routeToKindoperOrder2(kindOper: KindOper, row) {
    // console.log("prev", kindOper);
    if (kindOper === KindOper.Preview) {
      // console.log("prev");
        this.router.navigate([`${KindOper[kindOper]}/${row.Prefix}/${row.Id}`]);
     
    } else if (!this.User.IsTrader || row.IsTrader || kindOper === KindOper.Copy) {
      this.router.navigate([`NOrder/${KindOper[kindOper]}/${row.Prefix}/${row.Id}`]);
    } else if (this.User.IsTrader && row.IsTrader === false) {
      let dialogRef: MatDialogRef<OrderEditDialogComponent, any>;
      dialogRef = this.dialog.open(OrderEditDialogComponent, { data: { row: row, kindOper: kindOper, version: 'NOrder' } });
    }  
    this.orderRegisterService.goToPage = this.paginator.pageIndex;
  }

  public openValuationDialog(row: OrderList) {
    let dialogRef: MatDialogRef<OrderEstimateDialogComponent, any>;
    dialogRef = this.dialog.open(OrderEstimateDialogComponent, { data: row });
  }
  public openChildrenDialog(rows: OrderList[]) {
    const data = {
      OrderList: rows,
      IsTrader: this.User.IsTrader 
    };
    let dialogRef: MatDialogRef<ChildListComponent, any>;
    dialogRef = this.dialog.open(ChildListComponent, { data: data, minWidth: '1200px' });
    this.subscriptions.push(dialogRef.afterClosed().subscribe((result) => {
        
    }));
  }
  public openSendMailWithOrderDialog(id: number, b2BId: string, email: string) {
    const contractor = JSON.parse(sessionStorage.getItem('_slc')) as Contractor;

    this.contractorNumber = contractor.ContractorNumber;
    // console.log('user', contractor);
    
    const data: MailWithOrder = {
      Id: id,
      CultureId: this.tresci.jezyk_id,
      To: email,
      CC: '',
      Subject: b2BId +' (' + this.contractorNumber +' - '+ contractor.ContractorName +  ') ',
      Specification: true,
      Valuation: true,
    };
    let dialogRef: MatDialogRef<SendMailWithOrderDialogComponent, any>;
    dialogRef = this.dialog.open(SendMailWithOrderDialogComponent, { data: data, minWidth: '1200px' });
    this.subscriptions.push(dialogRef.afterClosed().subscribe((result) => {
      let dialogRef2: MatDialogRef<UniversalDialogComponent, any>;
      let data2: DialogData;
      if (result === true) {
        data2 = {
          title: '',
          content: 'mail_zostal_wyslany',
          answerOk: true,
        };
      } else {
        data2 = {
          title: '',
          content: 'mail_nie_zostal_wyslany',
          answerOk: true,
        };
      }
      dialogRef2 = this.dialog.open(UniversalDialogComponent, { data: data2 });
    }));
    
  }

  recalculateEstimate(id: number) {
    let dialogRef: MatDialogRef<UniversalDialogComponent, any>;
    const data: DialogData = {
      title: '',
      content: 'czy_na_pewno_przeliczyc',
      answerOk: false,
    };
    dialogRef = this.dialog.open(UniversalDialogComponent, { data: data });
    this.subscriptions.push(dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        this.IsLoadingResults = true;
        this.subscriptions.push(this.orderRegisterService.generateValuation(id).subscribe(() => {
          this.refreshEmit.emit({
            value: true,
          });
        }));
      }
    }));
  }

  labelsMatPaginator(): void {
    this.paginator._intl.itemsPerPageLabel = '';
    this.paginator._intl.firstPageLabel = '';
    this.paginator._intl.previousPageLabel = '';
    this.paginator._intl.nextPageLabel = '';
    this.paginator._intl.lastPageLabel = '';
    this.paginator._intl.getRangeLabel = rangeLabel;
  }
}

const rangeLabel = (page: number, pageSize: number, length: number) => {
  if (length === 0 || pageSize === 0) {
    return `0 / ${length}`;
  }

  length = Math.max(length, 0);

  const startIndex = page * pageSize;

  const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;

  return `${startIndex + 1} - ${endIndex} / ${length}`;
};
